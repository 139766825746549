import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { TiDelete } from "react-icons/ti";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";

import AddCards from "../../Components/AddCards";
import { Button, Modal } from "rsuite";

import "./index.css";
import {
  getCultura,
  getConfiguracaRelatorio,
  deleteConfigurcao,
} from "../../../../Services/programation.service";
import { ConfiguracaoGlebaContext } from "../../../../contexts/ConfiguracaoGlebaContext";
import { ToastContainer, toast } from "react-toastify";

const PaineGleba = ({ data, onDelete }) => {
  const [isMaximized, setIsMaximized] = useState(!!data.programationRelatorio);
  const [showModalExcluirConfiguracao, setShowModalExcluirConfiguracao] =
    useState(false);

  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );
  const handleMaximize = () => {
    setIsMaximized(true);
  };

  const handleMinimize = () => {
    setIsMaximized(false);
  };

  const culturaFetch = async () => {
    const {
      res: { data: culturaData },
    } = await getCultura();

    return {
      cultura: culturaData
        .filter((cultura) => !cultura.excluido)
        .map((cultura) => ({
          label: cultura.nome,
          value: cultura.id,
          ...cultura,
        })),
    };
  };

  const configOptionsQueryCultura = useQuery(
    ["configOptionsQueryCultura"],
    culturaFetch,
    {
      cultura: [],
    }
  );

  useEffect(() => {
    culturaFetch();
  }, [isMaximized]);

  const handleSelectChange = (name) => {
    return (option) => {
      const culturas = configuracaoGleba.culturas;
      const cultura = configOptionsQueryCultura.data.cultura.find(
        (cultura) => cultura.id === option.value
      );

      culturas[0] = {
        ...culturas[0],
        [name]: option.value,
        cultura,
      };
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };
  const programationRelatorioFetch = async () => {
    const { res } = await getConfiguracaRelatorio(configuracaoGleba);
    if (res?.data) {
      return res.data;
    }
    return {};
  };

  const programationRelatorioQuery = useQuery(
    [
      "configuracaoRelatorio",
      configuracaoGleba.tipoSafraId,
      configuracaoGleba.safraId,
      configuracaoGleba.glebaId,
    ],
    programationRelatorioFetch,
    {
      // enabled: !configuracaoGleba.id,
      initialData: undefined,
    }
  );
  const closeModalExcluirConfiguracao = () => {
    setShowModalExcluirConfiguracao(false);
  };

  const handleExcluirConfiguracao = async () => {
    const configId = programationRelatorioQuery?.data?.id;

    const { res } = await deleteConfigurcao(configId);
    if (res?.data) {
      closeModalExcluirConfiguracao();
      /* eslint-disable no-restricted-globals */
      location.reload();
      toast.success("Programação excluida com sucesso!");
    } else {
      closeModalExcluirConfiguracao();
      toast.error("Erro ao excluir programação!");
    }
  };
  return (
    <div className="gleba">
      <div className="header-gleba">
        <div className="title-gleba">
          <h4 className="title-gleba-text">Cultura</h4>
          <Select
            value={{
              label: configuracaoGleba?.culturas?.[0]?.cultura?.nome,
              value: configuracaoGleba?.culturas?.[0]?.cultura?.id,
            }}
            onChange={handleSelectChange("culturaId")}
            options={configOptionsQueryCultura?.data?.cultura}
          />
        </div>
        <div></div>
        <div className="config-header">
          {programationRelatorioQuery?.data && (
            <Button
              style={{ padding: "0px", background: "transparent" }}
              className="delete-button-gleba"
              onClick={setShowModalExcluirConfiguracao}
            >
              <TiDelete />
            </Button>
          )}
        </div>
      </div>

      {configuracaoGleba?.culturas?.[0]?.cultura?.id && (
        <div className="gleba-content">
          <AddCards data={data} />
        </div>
      )}

      <Modal
        open={showModalExcluirConfiguracao}
        onClose={closeModalExcluirConfiguracao}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Excluir programação</Modal.Header>
        <Modal.Body>
          <h4>
            Ao excluir a programação você perderá todas as informações lançadas.
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleExcluirConfiguracao}
            appearance="primary"
            className="button-excluir-produtor"
            style={{ width: "150px" }}
          >
            CONFIRMAR
          </Button>
          <Button
            onClick={closeModalExcluirConfiguracao}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaineGleba;
