import React, { useContext } from "react";
import Select from "react-select";
import { Button } from "rsuite";

import "./index.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  getTratamentoProduto,
  getTratamentoClasse,
} from "../../../../../../Services/programation.service";
import { useQuery } from "@tanstack/react-query";
import { ConfiguracaoGlebaContext } from "../../../../../../contexts/ConfiguracaoGlebaContext";

function TratamentoSementes() {
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );

  const handleAddRow = () => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      culturas: prevState.culturas.map((cultura) => ({
        ...cultura,
        tratamentoSementes: [
          ...(cultura?.tratamentoSementes ? cultura.tratamentoSementes : []),
          {
            dosagem: null,
            similar: false,
            produtoTratamentoId: "",
            classeTratamentoId: "",
          },
        ],
      })),
    }));
  };

  const handleSelectChange = (name, index) => {
    return (option) => {
      const culturas = configuracaoGleba.culturas;
      const tratamentoSementes = culturas[0].tratamentoSementes;
      tratamentoSementes[index] = {
        ...tratamentoSementes[index],
        [name]: option.value,
      };
      if (name === "produtoTratamentoId") {
        tratamentoSementes[index] = {
          ...tratamentoSementes[index],
          produtoTratamento: {
            ...tratamentoSementes[index],
            id: option.value,
            nome: option.label,
          },
        };
      }
      if (name === "classeTratamentoId") {
        tratamentoSementes[index] = {
          ...tratamentoSementes[index],
          classeTratamento: {
            ...tratamentoSementes[index],
            id: option.value,
            nome: option.label,
          },
        };
      }
      culturas[0] = {
        ...culturas[0],
        tratamentoSementes,
      };
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };

  const handleInputChange = (name, index, type = "text") => {
    return (e) => {
      const { value } = e.target;
      const culturas = configuracaoGleba.culturas;
      const tratamentoSementes = culturas[0].tratamentoSementes;

      tratamentoSementes[index] = {
        ...tratamentoSementes[index],
        [name]: type === "text" ? value : +value,
      };
      culturas[0] = {
        ...culturas[0],
        tratamentoSementes,
      };

      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };

  const TratamentoConfigurationFetch = async () => {
    const {
      res: { data: classeTratamentoData },
    } = await getTratamentoClasse();
    const {
      res: { data: produtoTratamentoData },
    } = await getTratamentoProduto();
    return {
      classesTratamento: classeTratamentoData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      produtosTratamento: produtoTratamentoData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      similar: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    };
  };
  const configOptionsQueryTratamento = useQuery(
    ["configOptionsQueryTratamento"],
    TratamentoConfigurationFetch,
    {
      classesTratamento: [],
      produtosTratamento: [],
      tipoTratamento: [],
      similar: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    }
  );

  const handleRemoveRow = (index) => {
    const culturas = configuracaoGleba.culturas;
    const tratamentoSementes = [...culturas[0].tratamentoSementes];
    tratamentoSementes.splice(index, 1);
    culturas[0].tratamentoSementes = tratamentoSementes;
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      culturas,
    }));
  };

  return (
    <div className="table-responsive">
      <div className="title-card">TRATAMENTO DE SEMENTES</div>
      {configuracaoGleba?.culturas?.lenght !== 0 ? (
        <table className="table">
          <tbody style={{ width: "100%", marginBottom: "0px" }}>
            {(configuracaoGleba?.culturas?.[0]?.tratamentoSementes || []).map(
              (rowData, index) => (
                <tr
                  style={{
                    display: "flex",
                    width: "max-content",
                    boxShadow: " 0px -4px 12px -1px rgba(0,0,0,0.45);",
                    margin: "10px",
                    // border: "solid 2px #dfdfdf",
                    borderRadius: "6px",
                    padding: "5px",
                    background: "rgba(1, 104, 32, 0.1)",
                  }}
                  key={`tr-${index}`}
                >
                  <div className="column-configs-table">
                    <th className="correct-inline">Produto</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        className="border-select"
                        value={{
                          label: rowData?.produtoTratamento?.nome,
                          value: rowData?.produtoTratamento?.id,
                        }}
                        onChange={handleSelectChange(
                          "produtoTratamentoId",
                          index
                        )}
                        // onChange={setSelectedOption}
                        options={
                          configOptionsQueryTratamento?.data?.produtosTratamento
                        }
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Classe Tratamento</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        value={{
                          label: rowData?.classeTratamento?.nome,
                          value: rowData?.classeTratamento?.id,
                        }}
                        // onChange={setSelectedOption}
                        onChange={handleSelectChange(
                          "classeTratamentoId",
                          index
                        )}
                        options={
                          configOptionsQueryTratamento?.data?.classesTratamento
                        }
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Dosagem (l ou Kg)</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        value={rowData?.dosagem}
                        onChange={handleInputChange("dosagem", index, "number")}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Tipo</th>
                    <td>
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        disabled={true}
                        className="input-atributo"
                        type="text"
                        value={
                          configuracaoGleba.culturas[0]?.cultura
                            ?.isSementePorSaca === true
                            ? "60.000 sem."
                            : "100 Kg sem."
                        }
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Similar</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        defaultValue={{
                          label: rowData.similar ? "Sim" : "Não",
                          value: rowData.similar,
                        }}
                        onChange={handleSelectChange("similar", index)}
                        options={configOptionsQueryTratamento?.data?.similar}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">
                      Quantidade total (l ou Kg)
                    </th>
                    <td>
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        disabled={true}
                        className="input-atributo"
                        type="text"
                        value={parseFloat(
                          configuracaoGleba.culturas[0]?.cultura
                            ?.isSementePorSaca !== false
                            ? configuracaoGleba.culturas[0]?.area
                              ? ((rowData.dosagem *
                                  configuracaoGleba.culturas[0]
                                    ?.sementePorSaca) /
                                  60000) *
                                configuracaoGleba.culturas[0]?.area *
                                ((configuracaoGleba.culturas[0]?.quantidade *
                                  10000) /
                                  configuracaoGleba.culturas[0]?.sementePorSaca)
                              : ((rowData.dosagem *
                                  configuracaoGleba.culturas[0]
                                    ?.sementePorSaca) /
                                  60000) *
                                configuracaoGleba.defaultValues.area *
                                ((configuracaoGleba.culturas[0]?.quantidade *
                                  10000) /
                                  configuracaoGleba.culturas[0]?.sementePorSaca)
                            : configuracaoGleba.culturas[0]?.cultura?.isPms !==
                              false
                            ? configuracaoGleba.culturas[0]?.area
                              ? rowData.dosagem *
                                ((configuracaoGleba.culturas[0]?.area *
                                  ((configuracaoGleba.culturas[0]?.quantidade *
                                    10000 *
                                    configuracaoGleba.culturas[0]?.pms) /
                                    1000)) /
                                  100)
                              : rowData.dosagem *
                                ((configuracaoGleba.defaultValues.area *
                                  ((configuracaoGleba.culturas[0]?.quantidade *
                                    10000 *
                                    configuracaoGleba.culturas[0]?.pms) /
                                    1000)) /
                                  100)
                            : configuracaoGleba.culturas[0]?.area
                            ? (rowData.dosagem *
                                (configuracaoGleba.culturas[0]?.area *
                                  configuracaoGleba.culturas[0]?.kgHa)) /
                              100
                            : (rowData.dosagem *
                                (configuracaoGleba.defaultValues.area *
                                  configuracaoGleba.culturas[0]?.kgHa)) /
                              100
                          //   true
                          // : configuracaoGleba.culturas[0]?.cultura?.isPms !==
                          //   true
                          // ? (rowData.dosagem *
                          //     configuracaoGleba.culturas[0]?.quantidade
                          //       .kgHa) /
                          //   100
                          // : configuracaoGleba.culturas[0]?.area
                          // ? rowData.dosagem *
                          //   ((configuracaoGleba.culturas[0]?.area *
                          //     ((configuracaoGleba.culturas[0]?.quantidade *
                          //       10000 *
                          //       configuracaoGleba.culturas[0]?.pms) /
                          //       1000)) /
                          //     100)
                          // : rowData.dosagem *
                          //   ((configuracaoGleba.defaultValues.area *
                          //     ((configuracaoGleba.culturas[0]?.quantidade *
                          //       10000 *
                          //       configuracaoGleba.culturas[0]?.pms) /
                          //       1000)) /
                          //     100)
                        )
                          .toFixed(2)
                          .replace(/\./g, ",")}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Estádio</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="text"
                        value={rowData?.estadio}
                        onChange={handleInputChange("estadio", index, "text")}
                      />
                    </td>
                  </div>
                  <th>
                    <button
                      className="button-remove"
                      variant="danger"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </th>
                </tr>
              )
            )}
            <button
              className="button-add"
              variant="danger"
              onClick={handleAddRow}
            >
              <IoMdAddCircleOutline />
            </button>
          </tbody>
        </table>
      ) : (
        <>
          <div className="no-data-info">Você não possui dados cadastrados.</div>
          <Button
            className="button-add1"
            variant="danger"
            onClick={() => handleAddRow()}
          >
            <IoMdAddCircleOutline />
          </Button>
        </>
      )}
    </div>
  );
}

export default TratamentoSementes;
