import React, { useState, useContext } from "react";
import Select from "react-select";
import { Button } from "rsuite";
import { useQuery } from "@tanstack/react-query";

import "./index.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { ConfiguracaoGlebaContext } from "../../../../../../contexts/ConfiguracaoGlebaContext";
import { getProdutoInseticida } from "../../../../../../Services/programation.service";
const options = [
  { value: "Produtor1", label: "Produtor1" },
  { value: "Produtor2", label: "Produtor2" },
  { value: "Produtor3", label: "Produtor3" },
];
function Inseticidas() {
  const [tableData, setTableData] = useState([]);
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );

  const [selectedOption, setSelectedOption] = useState(null);
  const handleAddRow = () => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      culturas: prevState.culturas.map((cultura) => ({
        ...cultura,
        inseticida: [
          ...(cultura?.inseticida ? cultura.inseticida : []),
          {
            produtoInseticidaId: "",
            quantidade: null,
            similar: false,
          },
        ],
      })),
    }));
  };

  const handleRemoveRow = (index) => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      culturas: prevState.culturas.map((cultura) => ({
        ...cultura,
        inseticida: cultura.inseticida.filter((_, i) => i !== index),
      })),
    }));
  };
  const handleChange = (event, index, column) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][column] = event.target.value;
    setTableData(updatedTableData);
  };
  const InseticidaConfigurationFetch = async () => {
    const {
      res: { data: produtoInseticidaData },
    } = await getProdutoInseticida();

    return {
      produtoInseticida: produtoInseticidaData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      similar: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    };
  };
  const configOptionsQueryInseticida = useQuery(
    ["configOptionsQueryInseticida"],
    InseticidaConfigurationFetch,
    {
      produtoInseticida: [],

      similar: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    }
  );
  const handleSelectChange = (name, index) => {
    return (option) => {
      const culturas = configuracaoGleba.culturas;
      const inseticida = culturas[0].inseticida;
      inseticida[index] = {
        ...inseticida[index],
        [name]: option.value,
      };
      if (name === "produtoInseticidaId") {
        inseticida[index] = {
          ...inseticida[index],
          produtoInseticida: {
            ...inseticida[index],
            id: option.value,
            nome: option.label,
          },
        };
      }
      culturas[0] = {
        ...culturas[0],
        inseticida,
      };
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };
  const handleInputChange = (name, index) => {
    return (e) => {
      const { value } = e.target;
      const culturas = configuracaoGleba.culturas;
      const inseticida = culturas[0].inseticida;

      inseticida[index] = {
        ...inseticida[index],
        [name]: value,
      };
      culturas[0] = {
        ...culturas[0],
        inseticida,
      };
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };
  return (
    <div className="table-responsive">
      <div className="title-card">INSETICIDAS</div>
      {configuracaoGleba.culturas.length !== 0 ? (
        <table className="table">
          <tbody style={{ width: "100%", marginBottom: "0px" }}>
            {(configuracaoGleba?.culturas?.[0]?.inseticida || []).map(
              (rowData, index) => (
                <tr
                  style={{
                    display: "flex",
                    width: "max-content",
                    boxShadow: " 0px -4px 12px -1px rgba(0,0,0,0.45);",
                    margin: "10px",
                    // border: "solid 2px #dfdfdf",
                    borderRadius: "6px",
                    padding: "5px",
                    background: "rgba(1, 104, 32, 0.1)",
                  }}
                  key={index}
                >
                  <div className="column-configs-table">
                    <th className="correct-inline">Produto</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        value={{
                          label: rowData?.produtoInseticida?.nome,
                          value: rowData?.produtoInseticida?.id,
                        }}
                        onChange={handleSelectChange(
                          "produtoInseticidaId",
                          index
                        )}
                        // onChange={setSelectedOption}
                        options={
                          configOptionsQueryInseticida?.data?.produtoInseticida
                        }
                      />
                    </td>
                  </div>

                  <div className="column-configs-table">
                    <th className="correct-inline">Quantidade/ha (l ou Kg)</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        defaultValue={rowData?.quantidade}
                        onChange={handleInputChange("quantidade", index)}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Área</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        value={
                          rowData.area
                            ? rowData.area
                            : configuracaoGleba.defaultValues.area
                        }
                        onChange={handleInputChange("area", index)}
                      />
                    </td>
                  </div>
                  {/* <div className="column-configs-table">
                  <th className="correct-inline">Área</th>
                  <td>
                    <input
                      className="input-atributo"
                      type="text"
                      value={rowData.coluna3}
                      onChange={(e) => handleChange(e, index, "coluna3")}
                    />
                  </td>
                </div> */}

                  <div className="column-configs-table">
                    <th className="correct-inline">Similar</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        defaultValue={{
                          label: rowData.similar ? "Sim" : "Não",
                          value: rowData.similar,
                        }}
                        onChange={handleSelectChange("similar", index)}
                        options={configOptionsQueryInseticida?.data?.similar}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">
                      Quantidade Total (l ou Kg)
                    </th>
                    <td>
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        disabled="true"
                        className="input-atributo"
                        type="number"
                        value={parseFloat(
                          rowData?.area * rowData?.quantidade ||
                            configuracaoGleba.defaultValues.area *
                              rowData?.quantidade
                        ).toFixed(2)}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Estádio</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="text"
                        value={rowData?.estadio}
                        onChange={handleInputChange("estadio", index, "text")}
                      />
                    </td>
                  </div>
                  <th>
                    <button
                      className="button-remove"
                      variant="danger"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </th>
                </tr>
              )
            )}
            <button
              className="button-add"
              variant="danger"
              onClick={() => handleAddRow()}
            >
              <IoMdAddCircleOutline />
            </button>
          </tbody>
        </table>
      ) : (
        <>
          <div className="no-data-info">Você não possui dados cadastrados.</div>
          <Button
            className="button-add1"
            variant="danger"
            onClick={() => handleAddRow()}
          >
            <IoMdAddCircleOutline />
          </Button>
        </>
      )}
    </div>
  );
}

export default Inseticidas;
