import React, { useState } from "react";
import { Sidebar, Sidenav, Nav, Dropdown } from "rsuite";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import "../Hamburger/Hamburger.css";

function MenuHamburguerAnimado() {
  const [expand, setExpand] = useState(false);

  const handleToggle = () => {
    setExpand(!expand);
  };
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/home");
  };
  const handleNavigateCadastroInsumos = () => {
    navigate("/CadastroInsumos");
  };
  const handleNavigateGerarRelatorios = () => {
    navigate("/GerarRelatorios");
  };
  const handleNavigateClonarGleba = () => {
    navigate("/ClonarGleba");
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const handleNavigateCadastroProdutor = () => {
    navigate("/CadastroProdutor");
  };

  return (
    <div className="headerHamburguer">
      <Sidebar
        style={{ display: "flex", flexDirection: "column" }}
        width={200}
        // heigth={1500}
        // collapsible
        expanded={expand}
        onToggle={handleToggle}
      >
        <Sidenav onSelect={() => setExpand(false)}>
          <Sidenav.Body className="headerHamburguer">
            <Nav className="bodyHamburguer">
              <Dropdown
                icon={
                  <>
                    <AiOutlineMenu
                      style={{ fontSize: "45px", color: "white" }}
                      icon="magic"
                    />
                  </>
                }
              >
                <Dropdown.Item
                  onClick={handleNavigateHome}
                  className="itemDropdown"
                >
                  PROGRAMAÇÃO
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleNavigateCadastroInsumos}
                  className="itemDropdown"
                >
                  CADASTRO DE INSUMOS
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleNavigateCadastroProdutor}
                  className="itemDropdown"
                >
                  CADASTRO GERAL
                </Dropdown.Item>
                <Dropdown.Item
                  className="itemDropdown"
                  onClick={handleNavigateGerarRelatorios}
                >
                  GERAR RELATÓRIOS
                </Dropdown.Item>
                <Dropdown.Item
                  className="itemDropdown"
                  onClick={handleNavigateClonarGleba}
                >
                  CLONAR GLEBA
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleLogout}
                  className="itemDropdown"
                >
                  SAIR
                </Dropdown.Item>{" "}
              </Dropdown>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
    </div>
  );
}

export default MenuHamburguerAnimado;
