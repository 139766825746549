import React, { useState } from "react";

import Select from "react-select";
import "../../../PanelGerarRelatorios/index.css";
import { Button } from "rsuite";
import { useQuery } from "@tanstack/react-query";

import {
  getDowloadConfiguracaoPorGleba,
  getSafras,
  getTipoSafra,
  getProdutor,
  getFazenda,
} from "../../../../../Services/programation.service";
export const RelatorioPorGleba = () => {
  const [selectOption, setSelectOption] = useState({
    tiposSafra: [],
    safras: [],
    produtores: [],
  });
  const getTipoSafrafetch = async () => {
    const { res } = await getTipoSafra();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((tipoSafra) => !tipoSafra.excluido)
        .map((tipoSafra) => ({
          label: tipoSafra.nome,
          value: tipoSafra.id,
        }));
    }
    return [];
  };

  const getSafrasfetch = async () => {
    const { res } = await getSafras();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((safra) => !safra.excluido)
        .map((safra) => ({
          label: safra.nome,
          value: safra.id,
        }));
    }
    return [];
  };
  const getProdutorFetch = async () => {
    const { res } = await getProdutor();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produtor) => !produtor.excluido)
        .map((produtor) => ({
          label: produtor.nome,
          value: produtor.id,
        }));
    }
    return [];
  };
  const getFazendasfetchAgrupado = async () => {
    const { res } = await getFazenda(selectOption?.produtor?.produtorId);
    if (res?.data) {
      const { data } = res;
      return data
        .filter((fazenda) => !fazenda.excluido)
        .map((fazenda) => ({
          label: fazenda.nome,
          value: fazenda.id,
        }));
    }
    return [];
  };

  const handleSelectChangeSafra = (name) => {
    return (option) => {
      let safra = safrasQuery.data;
      safra = {
        ...safra,
        [name]: option.value,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        safra,
      }));
    };
  };
  const handleSelectChangeFazenda = (name) => {
    return (option) => {
      let fazenda = fazendaQueryAgrupado.data;
      fazenda = {
        ...fazenda,
        [name]: option.value,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        fazenda,
      }));
    };
  };
  const handleSelectChangeTipoSafra = (name) => {
    return (option) => {
      let tipoSafra = tiposSafraQuery.data;
      tipoSafra = {
        ...tipoSafra,
        [name]: option.value,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        tipoSafra,
      }));
    };
  };
  const handleSelectChangeProdutor = (name, label) => {
    return (option) => {
      let produtor = produtoresQuery.data;
      produtor = {
        ...produtor,
        [name]: option.value,
        [label]: option.label,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        produtor,
      }));
    };
  };

  const tiposSafraQuery = useQuery(["tiposSafraQuery"], getTipoSafrafetch, []);
  const safrasQuery = useQuery(["safrasQuery"], getSafrasfetch, []);
  const produtoresQuery = useQuery(["produtoresQuery"], getProdutorFetch, []);
  const fazendaQueryAgrupado = useQuery(
    ["fazendaQueryAgrupado", selectOption?.produtor?.produtorId],
    getFazendasfetchAgrupado,
    []
  );

  const handleDownloadGleba = async () => {
    const { res } = await getDowloadConfiguracaoPorGleba(
      selectOption.tipoSafra.tipoSafraId,
      selectOption.safra.safraId,
      selectOption.fazenda.fazendaId
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Download_de_configuração_por_gleba.pdf");
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div className="generating-container-reports">
      <div className="reports-content">
        <h6
          style={{
            fontWeight: "bold",
            color: "rgba(1, 104, 32, 0.81)",
            marginBottom: "10px",
          }}
        >
          Relatório por gleba
        </h6>
        <div className="select-reports">
          <div className="select-content-relatorio-agrupado">
            Selecione o Produtor
            <Select
              defaultValue={{}}
              onChange={handleSelectChangeProdutor("produtorId")}
              options={produtoresQuery.data}
            />
          </div>
          <div className="select-content-relatorio-agrupado">
            Selecione a Fazenda
            <Select
              defaultValue={{}}
              onChange={handleSelectChangeFazenda("fazendaId")}
              options={fazendaQueryAgrupado.data}
              isDisabled={!selectOption?.produtor?.produtorId}
            />
          </div>
          <div className="select-content-relatorio-agrupado">
            Selecione a Safra
            <Select
              defaultValue={{}}
              onChange={handleSelectChangeSafra("safraId")}
              options={safrasQuery.data}
              isDisabled={!selectOption?.produtor?.produtorId}
            />
          </div>
          <div className="select-content-relatorio-agrupado">
            Selecione o Tipo Safra
            <Select
              defaultValue={{}}
              onChange={handleSelectChangeTipoSafra("tipoSafraId")}
              options={tiposSafraQuery.data}
              isDisabled={!selectOption?.produtor?.produtorId}
            />
          </div>
        </div>
        <Button
          className="button-generate-reports"
          variant="danger"
          size="lg"
          onClick={handleDownloadGleba}
        >
          Gerar Relatório
        </Button>
      </div>
    </div>
  );
};
