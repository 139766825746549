// import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Panel from "./Components/Panel";
// import AddCards from "./Components/AddCards/index";
import AddGleba from "./AddGleba";
import { useContext, useState } from "react";
import { ConfiguracaoGlebaContext } from "../../contexts/ConfiguracaoGlebaContext";
const Home = () => {
  const { setConfiguracaoGleba } = useContext(ConfiguracaoGlebaContext);
  // const [formValue, setFormValue] = useState();
  const handleFormValue = (name) => {
    return (option) => {
      setConfiguracaoGleba((prev) => ({
        ...prev,
        [name]: option.value,
      }));
    };
  };

  return (
    <div className="container-home">
      <Header />
      <Panel onFormValueChange={handleFormValue} />
      {/* <AddCards /> */}
      <AddGleba />
    </div>
  );
};

export default Home;
