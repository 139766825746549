import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import {
  getConfiguracaoGleba,
  getGlebas,
  getSafras,
  getTipoSafra,
  getProdutor,
  getFazenda,
} from "../../../../Services/programation.service";
import { ConfiguracaoGlebaContext } from "../../../../contexts/ConfiguracaoGlebaContext";

const Panel = ({ onFormValueChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const { setConfiguracaoGleba, configuracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );

  const getTipoSafrafetch = async () => {
    const { res } = await getTipoSafra();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((tipoSafra) => !tipoSafra.excluido)
        .map((tipoSafra) => ({
          label: tipoSafra.nome,
          value: tipoSafra.id,
        }));
    }
    return [];
  };
  const getSafrasfetch = async () => {
    const { res } = await getSafras();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((safra) => !safra.excluido)
        .map((safra) => ({
          label: safra.nome,
          value: safra.id,
        }));
    }
    return [];
  };
  const getGlebasfetch = async () => {
    const { res } = await getGlebas(configuracaoGleba.fazendaId);
    if (configuracaoGleba.fazendaId) {
      if (res?.data) {
        const { data } = res;

        return data
          .filter((glebas) => !glebas.excluido)
          .map((glebas) => ({
            label: glebas.nome,
            value: glebas.id,
            area: glebas.area,
          }));
      }
    }
    return [];
  };

  const getProdutorFetch = async () => {
    const { res } = await getProdutor();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produtor) => !produtor.excluido)
        .map((produtor) => ({
          label: produtor.nome,
          value: produtor.id,
        }));
    }
    return [];
  };

  const getFazendaFetch = async () => {
    if (configuracaoGleba.produtorId) {
      const { res } = await getFazenda(configuracaoGleba.produtorId);
      if (res?.data) {
        const { data } = res;
        return data
          .filter((fazenda) => !fazenda.excluido)
          .map((fazenda) => ({
            label: fazenda.nome,
            value: fazenda.id,
          }));
      }
    }

    return [];
  };

  const tiposSafraQuery = useQuery(["tiposSafraQuery"], getTipoSafrafetch, []);
  const safrasQuery = useQuery(["safrasQuery"], getSafrasfetch, []);

  const glebasQuery = useQuery(
    ["glebasQuery", configuracaoGleba.fazendaId],
    getGlebasfetch,
    []
  );

  const produtorQuery = useQuery(["produtorQuery"], getProdutorFetch, []);
  const fazendasQuery = useQuery(
    ["fazendasQuery", configuracaoGleba.produtorId],
    getFazendaFetch,
    []
  );

  const handleSelectChangeGleba = (name) => {
    return (option) => {
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        [name]: option.value,
        defaultValues: {
          area: option.area,
        },
      }));
    };
    // setConfiguracaoGleba;
    //  onFormValueChange(name);
  };

  return (
    <div className="panel-container">
      <div className="header-panel-home">
        <div className="select-component">
          <h4 style={{ color: "white" }}>Produtor</h4>
          <Select
            name="Selecione"
            defaultValue={selectedOption}
            onChange={onFormValueChange("produtorId")}
            options={produtorQuery.data}
          />
        </div>
        <div className="select-component">
          <h4 style={{ color: "white" }}>Fazenda</h4>
          <Select
            name="Selecione"
            style={{ width: "180px !important" }}
            defaultValue={selectedOption}
            onChange={onFormValueChange("fazendaId")}
            options={fazendasQuery.data}
            isDisabled={!configuracaoGleba.produtorId}
          />
        </div>
      </div>
      <div className="select-primary">
        {!!configuracaoGleba.produtorId && !!configuracaoGleba.fazendaId && (
          <div style={{ display: "flex", width: "100%" }}>
            <div className="select-component">
              <h4>Glebas</h4>
              <Select
                name="Selecione"
                label="Selecione"
                defaultValue={selectedOption}
                onChange={handleSelectChangeGleba("glebaId")}
                options={glebasQuery.data}
              />
            </div>
            <div className="select-component">
              <h4>Tipo safra</h4>
              <Select
                name="Selecione"
                label="Selecione"
                defaultValue={selectedOption}
                onChange={onFormValueChange("tipoSafraId")}
                options={tiposSafraQuery.data}
              />
            </div>
            <div className="select-component">
              {" "}
              <h4>Safras</h4>
              <Select
                name="Selecione"
                label="Selecione"
                defaultValue={selectedOption}
                onChange={onFormValueChange("safraId")}
                options={safrasQuery.data}
              />
            </div>
          </div>
        )}
      </div>
      <div className="select-second"></div>
    </div>
  );
};

export default Panel;
