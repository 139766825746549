import React, { useState, useContext, useEffect } from "react";
import PaineGleba from "./PainelGleba";
import "./index.css";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FiSave } from "react-icons/fi";

import { Button } from "rsuite";
import { useQuery } from "@tanstack/react-query";
import {
  getConfiguracaRelatorio,
  createGleba,
} from "../../../Services/programation.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfiguracaoGlebaContext } from "../../../contexts/ConfiguracaoGlebaContext";

import { Circles } from "react-loader-spinner";
const AddGleba = () => {
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );
  const [cards, setCards] = useState([]);
  const isAddButtonVisible = !!configuracaoGleba?.culturas?.length;
  const isButtonsVisible =
    configuracaoGleba.tipoSafraId &&
    configuracaoGleba.safraId &&
    configuracaoGleba.glebaId;
  const [isLoading, setIsLoading] = useState(false);

  const handleAddCard = () => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,

      culturas: [
        {
          area: null,
          espaco: null,
          similar: false,
          quantidade: null,
          cultivarId: "",
          propositoId: "",
          ordemPlantio: null,
        },
      ],
    }));
  };

  const handleDeleteCard = (index) => {
    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    setCards(updatedCards);
  };

  const programationRelatorioFetch = async () => {
    if (isButtonsVisible) {
      setIsLoading(true);
      const { res } = await getConfiguracaRelatorio(configuracaoGleba);

      if (res?.data) {
        setIsLoading(false);
        return res.data;
      } else {
        setIsLoading(false);
        return {};
      }
    }
  };

  const programationRelatorioQuery = useQuery(
    [
      "configuracaoRelatorioAddGleba",
      configuracaoGleba.tipoSafraId,
      configuracaoGleba.safraId,
      configuracaoGleba.glebaId,
    ],
    programationRelatorioFetch,
    {
      // enabled: !configuracaoGleba.id,
      initialData: undefined,
    }
  );
  const formValueMap = (data) => {
    return {
      culturas: data.culturas.map((cultura) => ({
        ...cultura,
        culturaId: cultura?.cultura?.id,
        cultivarId: cultura?.cultivar?.id,
        propositoId: cultura?.proposito?.id,
        tratamentoSementes: (cultura?.tratamentoSementes || []).map(
          (tratamentoSemente) => ({
            ...tratamentoSemente,
            produtoTratamentoId: tratamentoSemente?.produtoTratamento?.id,
            classeTratamentoId: tratamentoSemente?.classeTratamento?.id,
          })
        ),
        adubacao: (cultura?.adubacao || []).map((adubacao) => ({
          ...adubacao,
          produtoAdubacaoId: adubacao?.produtoAdubacao?.id,
          epocaAdubacaoId: adubacao?.epocaAdubacao?.id,
          tipoAdubacaoId: adubacao?.tipoAdubacao?.id,
        })),
        adubacaoFolhar: (cultura?.adubacaoFoliar || []).map(
          (adubacaoFoliar) => ({
            ...adubacaoFoliar,
            produtoAdubacaoFolharId: adubacaoFoliar?.produtoAdubacaoFolhar?.id,
          })
        ),
        herbicida: (cultura?.herbicida || []).map((herbicida) => ({
          ...herbicida,
          produtoHerbicidaId: herbicida?.produtoHerbicida?.id,
          tipoHerbicidaId: herbicida?.tipoHerbicida?.id,
        })),
        inseticida: (cultura?.inseticida || []).map((inseticida) => ({
          ...inseticida,
          produtoInseticidaId: inseticida?.produtoInseticida?.id,
        })),
        fungicida: (cultura?.fungicida || []).map((fungicida) => ({
          ...fungicida,
          produtoFungicidaId: fungicida?.produtoFungicida?.id,
        })),
        outrosProdutos: (cultura?.outrosProdutos || []).map(
          (outrosProdutos) => ({
            ...outrosProdutos,
            produtoOutrosProdutosId: outrosProdutos?.produtoOutrosProdutos?.id,
          })
        ),
      })),
    };
  };

  useEffect(() => {
    if (programationRelatorioQuery?.data?.id) {
      const dataMap = formValueMap(programationRelatorioQuery.data);

      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        ...programationRelatorioQuery.data,
        ...dataMap,
      }));
    } else {
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas: [],
      }));
    }
  }, [programationRelatorioQuery?.data]);

  const handleSubmit = async (body) => {
    const { res } = await createGleba(configuracaoGleba);
    setIsLoading(true);
    if (res) {
      setIsLoading(false);
      toast.success("Configuração salva com sucesso!");
    } else {
      setIsLoading(false);
      toast.error("Falha ao salvar a configuração!");
    }
  };

  return (
    <div className="panel-gleba">
      {!!configuracaoGleba?.culturas?.length && (
        <PaineGleba
          onDelete={() => handleDeleteCard(0)}
          data={{ programationRelatorio: configuracaoGleba }}
        />
      )}
      {cards.map((card, index) => (
        <PaineGleba
          key={index}
          onDelete={() => handleDeleteCard(index)}
          data={
            {}
            // programationGleba: programationGlebaQuery.data,
          }
        />
      ))}
      {isButtonsVisible && isLoading !== true ? (
        <div className="select-component-insumos">
          {!isAddButtonVisible && (
            <div
              style={{
                justifyContent: "left",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              A gleba ainda não possui uma configuração. Clique em adicionar
              para continuar com a configuração.
              <Button
                className="button-add-gleba"
                variant="danger"
                size="lg"
                onClick={handleAddCard}
              >
                Adicionar Cultura
                <IoMdAddCircleOutline />
              </Button>
            </div>

            //ADICIONAR TEXTO
          )}
          {isAddButtonVisible && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  width: "170px",
                  padding: "23px 16px",
                  margin: " 30px 0 0 0 ",
                }}
                className="button-add-gleba"
                variant="danger"
                size="lg"
                onClick={handleSubmit}
              >
                Salvar Gleba
                <FiSave />
              </Button>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "100px",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "rgb(163 161 161 / 26%)",
            top: "0",
            left: "0",
            alignItems: "center",
          }}
        >
          {" "}
          <Circles
            height="80"
            width="80"
            color="rgba(1, 104, 32, 0.81)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Same as
      {/* <ToastContainer /> */}
    </div>
  );
};

export default AddGleba;
