import React, { useState, useEffect } from "react";

import Select from "react-select";
import "../../../PanelGerarRelatorios/index.css";
import { Button } from "rsuite";
import { useQuery } from "@tanstack/react-query";

import {
  getDowloadConfiguracaoAgrupada,
  getSafras,
  getTipoSafra,
  getProdutor,
  getFazenda,
  getCultura,
} from "../../../../../Services/programation.service";
export const RelatorioAgrupado = () => {
  const [selectName, setSelectName] = useState("");
  const [selectRelativeName, setSelecRelativeName] = useState("");
  const [selectOption, setSelectOption] = useState({
    tiposSafra: [],
    safra: [],
    cultura: [],
    fazenda: [],
    grupo: [
      {
        label: "Fazenda",
        value: 1,
      },
      {
        label: "Cultura",
        value: 2,
      },
      {
        label: "Produtor",
        value: 3,
      },
    ],
    grupoId: null,
  });

  const getTipoSafrafetch = async () => {
    const { res } = await getTipoSafra();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((tipoSafra) => !tipoSafra.excluido)
        .map((tipoSafra) => ({
          label: tipoSafra.nome,
          value: tipoSafra.id,
        }));
    }
    return [];
  };
  const getProdutorFetch = async () => {
    const { res } = await getProdutor();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produtor) => !produtor.excluido)
        .map((produtor) => ({
          label: produtor.nome,
          value: produtor.id,
        }));
    }
    return [];
  };

  const getSafrasfetch = async () => {
    const { res } = await getSafras();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((safra) => !safra.excluido)
        .map((safra) => ({
          label: safra.nome,
          value: safra.id,
        }));
    }
    return [];
  };

  const getCulturaFetch = async () => {
    const { res } = await getCultura();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((cultura) => !cultura.excluido)
        .map((cultura) => ({
          label: cultura.nome,
          value: cultura.id,
        }));
    }
    return [];
  };

  const handleSelectChangeSafra = (name) => {
    return (option) => {
      let safra = safrasQuery.data;
      safra = {
        ...safra,
        [name]: option.value,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        safra,
      }));
    };
  };
  const handleSelectChangeFazenda = (name, label) => {
    return (option) => {
      let fazenda = fazendaQueryAgrupado.data;
      fazenda = {
        ...fazenda,
        [name]: option.value,
        [label]: option.label,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        fazenda,
      }));
    };
  };

  const handleSelectChangeTipoSafra = (name) => {
    return (option) => {
      let tipoSafra = tiposSafraQuery.data;
      tipoSafra = {
        ...tipoSafra,
        [name]: option.value,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        tipoSafra,
      }));
    };
  };

  const handleSelectChangeGrupo = (name) => {
    return (option) => {
      setSelectOption((prevState) => ({
        ...prevState,
        [name]: option.value,
      }));
    };
  };

  const handleSelectChangeProdutor = (name, label) => {
    return (option) => {
      let produtor = produtoresQuery.data;
      produtor = {
        ...produtor,
        [name]: option.value,
        [label]: option.label,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        produtor,
      }));
    };
  };

  const getFazendasfetchAgrupado = async () => {
    const { res } = await getFazenda(selectOption?.produtor?.produtorId);
    if (res?.data) {
      const { data } = res;
      return data
        .filter((fazenda) => !fazenda.excluido)
        .map((fazenda) => ({
          label: fazenda.nome,
          value: fazenda.id,
        }));
    }
    return [];
  };

  useEffect(() => {
    getFazendasfetchAgrupado();
  }, [selectOption.grupo.produtorId]);

  useEffect(() => {
    if (selectOption.grupoId === 1) {
      setSelectName("Fazenda");
      setSelecRelativeName(selectOption?.fazenda?.fazendaLabel);
    }
    if (selectOption.grupoId === 2) {
      setSelectName("Cultura");
      setSelecRelativeName(selectOption?.cultura?.culturaLabel);
    }
    if (selectOption.grupoId === 3) {
      setSelectName("Produtor");
      setSelecRelativeName(selectOption?.produtor?.produtorLabel);
    }
  }, [selectOption]);

  const handleSelectChangeCultura = (name, label) => {
    return (option) => {
      let cultura = culturasQuery.data;
      cultura = {
        ...cultura,
        [name]: option.value,
        [label]: option.label,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        cultura,
      }));
    };
  };

  const tiposSafraQuery = useQuery(["tiposSafraQuery"], getTipoSafrafetch, []);
  const safrasQuery = useQuery(["safrasQuery"], getSafrasfetch, []);
  const produtoresQuery = useQuery(["produtoresQuery"], getProdutorFetch, []);
  const fazendaQueryAgrupado = useQuery(
    ["fazendaQueryAgrupado", selectOption?.produtor?.produtorId],
    getFazendasfetchAgrupado,
    []
  );
  const culturasQuery = useQuery(["culturaQuery"], getCulturaFetch, []);

  const handleDownloadAgrupado = async () => {
    const { res } = await getDowloadConfiguracaoAgrupada(
      selectOption.tipoSafra.tipoSafraId,
      selectOption.safra.safraId,
      selectOption.fazenda.fazendaId,
      selectOption.cultura.culturaId,
      selectOption.grupoId,
      selectOption.produtor.produtorId
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "Download_de_configuração_por_" +
        selectName +
        "_" +
        selectRelativeName +
        ".pdf"
    );
    link.click();

    URL.revokeObjectURL(url);
  };
  return (
    <div className="generating-container-reports">
      <div className="reports-content">
        <h6
          style={{
            fontWeight: "bold",
            color: "rgba(1, 104, 32, 0.81)",
            marginBottom: "10px",
          }}
        >
          Relatório Agrupado
        </h6>

        <div className="select-reports">
          <div className="select-content-relatorio-agrupado">
            Selecione o Grupo
            <Select
              defaultValue={{}}
              onChange={handleSelectChangeGrupo("grupoId")}
              options={selectOption.grupo}
            />{" "}
          </div>
          {selectOption.grupoId === 1 && (
            <>
              <div className="select-content-relatorio-agrupado">
                Selecione o Produtor
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeProdutor("produtorId")}
                  options={produtoresQuery.data}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione a Fazenda
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeFazenda(
                    "fazendaId",
                    "fazendaLabel"
                  )}
                  options={fazendaQueryAgrupado.data}
                  isDisabled={!selectOption?.produtor?.produtorId}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione a Safra
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeSafra("safraId")}
                  isDisabled={!selectOption?.produtor?.produtorId}
                  options={safrasQuery.data}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione o Tipo Safra
                <Select
                  defaultValue={{}}
                  isDisabled={!selectOption?.produtor?.produtorId}
                  onChange={handleSelectChangeTipoSafra("tipoSafraId")}
                  options={tiposSafraQuery.data}
                />{" "}
              </div>{" "}
            </>
          )}
          {selectOption.grupoId === 2 && (
            <>
              <div className="select-content-relatorio-agrupado">
                Selecione o Produtor
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeProdutor("produtorId")}
                  options={produtoresQuery.data}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione a Fazenda
                <Select
                  isDisabled={!selectOption?.produtor?.produtorId}
                  defaultValue={{}}
                  onChange={handleSelectChangeFazenda("fazendaId")}
                  options={fazendaQueryAgrupado.data}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione a Cultura
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeCultura(
                    "culturaId",
                    "culturaLabel"
                  )}
                  options={culturasQuery.data}
                  isDisabled={!selectOption?.produtor?.produtorId}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione a Safra
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeSafra("safraId")}
                  options={safrasQuery.data}
                  isDisabled={!selectOption?.produtor?.produtorId}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione o Tipo Safra
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeTipoSafra("tipoSafraId")}
                  options={tiposSafraQuery.data}
                  isDisabled={!selectOption?.produtor?.produtorId}
                />
              </div>
            </>
          )}
          {selectOption.grupoId === 3 && (
            <>
              <div className="select-content-relatorio-agrupado">
                Selecione o Produtor
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeProdutor(
                    "produtorId",
                    "produtorLabel"
                  )}
                  options={produtoresQuery.data}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione a Safra
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeSafra("safraId")}
                  options={safrasQuery.data}
                  isDisabled={!selectOption?.produtor?.produtorId}
                />{" "}
              </div>
              <div className="select-content-relatorio-agrupado">
                Selecione o Tipo Safra
                <Select
                  defaultValue={{}}
                  onChange={handleSelectChangeTipoSafra("tipoSafraId")}
                  options={tiposSafraQuery.data}
                  isDisabled={!selectOption?.produtor?.produtorId}
                />
              </div>
            </>
          )}
        </div>
        <Button
          className="button-generate-reports"
          variant="danger"
          size="lg"
          onClick={handleDownloadAgrupado}
        >
          Gerar Relatório
        </Button>
      </div>
    </div>
  );
};
