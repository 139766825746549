import React, { useState, useContext } from "react";
import Select from "react-select";
import { Button } from "rsuite";
import { useQuery } from "@tanstack/react-query";
import { SelectPicker } from "rsuite";

import "./index.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { ConfiguracaoGlebaContext } from "../../../../../../contexts/ConfiguracaoGlebaContext";
import {
  getProdutoAdubacao,
  getTipoAdubacao,
  getEpocaAdubacao,
} from "../../../../../../Services/programation.service";

function Adubacao() {
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );

  const handleAddRow = () => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      culturas: prevState.culturas.map((cultura) => ({
        ...cultura,
        adubacao: [
          ...(cultura.adubacao || []),
          {
            id: Date.now(),
            quantidade: null,
            taxaVariavel: false,
            produtoAdubacaoId: "",
            epocaAdubacaoId: "",
            tipoAdubacaoId: "",
          },
        ],
      })),
    }));
  };

  const handleRemoveRow = (idToRemove) => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      culturas: prevState.culturas.map((cultura) => ({
        ...cultura,
        adubacao: cultura.adubacao.filter((item) => item.id !== idToRemove),
      })),
    }));
  };

  const AdubacaoConfigurationFetch = async () => {
    const {
      res: { data: produtoAdubacaoData },
    } = await getProdutoAdubacao();
    const {
      res: { data: tipoAdubacaoData },
    } = await getTipoAdubacao();
    const {
      res: { data: epocaAdubacaoData },
    } = await getEpocaAdubacao();
    return {
      produtoAdubacao: produtoAdubacaoData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      tipoAdubacao: tipoAdubacaoData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      epocaAdubacao: epocaAdubacaoData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      taxaVariavel: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    };
  };
  const configOptionsQueryAdubacao = useQuery(
    ["configOptionsQueryAdubacao"],
    AdubacaoConfigurationFetch,
    {
      produtoAdubacao: [],
      tipoAdubacao: [],
      epocaAdubacao: [],
      taxaVariavel: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    }
  );

  const handleSelectChange = (name, index) => {
    return (option) => {
      const culturas = configuracaoGleba.culturas;
      const adubacao = culturas[0].adubacao;
      adubacao[index] = {
        ...adubacao[index],
        [name]: option.value,
      };
      culturas[0] = {
        ...culturas[0],
        adubacao,
      };

      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };

  const handleInputChange = (name, index) => {
    return (e) => {
      const { value } = e.target;
      const culturas = configuracaoGleba.culturas;
      const adubacao = culturas[0].adubacao;

      adubacao[index] = {
        ...adubacao[index],
        [name]: value,
      };
      culturas[0] = {
        ...culturas[0],
        adubacao,
      };
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };

  // const colourStyles = {
  //   control: (styles) => ({
  //     ...styles,
  //     backgroundColor: "white",
  //     zIndex: "9999",
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isDisabled ? "red" : "blue",
  //       color: "#FFF",
  //       cursor: isDisabled ? "not-allowed" : "default",
  //       zIndex: "99999",
  //     };
  //   },
  // };

  return (
    <div className="table-responsive">
      <div className="title-card">ADUBAÇÃO</div>
      {configuracaoGleba.culturas.lenght !== 0 ? (
        <table className="table">
          <tbody style={{ width: "100%", marginBottom: "0px" }}>
            {(configuracaoGleba?.culturas?.[0]?.adubacao || []).map(
              (rowData, index) => (
                <tr
                  style={{
                    display: "flex",
                    width: "max-content",
                    boxShadow: " 0px -4px 12px -1px rgba(0,0,0,0.45);",
                    margin: "10px",
                    // border: "solid 2px #dfdfdf",
                    borderRadius: "6px",
                    padding: "5px",
                    background: "rgba(1, 104, 32, 0.1)",
                  }}
                  key={rowData.id}
                >
                  <div className="column-configs-table">
                    <th className="correct-inline">Produto</th>
                    <td>
                      <Select
                        // styles={colourStyles}
                        menuPosition="fixed"
                        defaultValue={{
                          label: rowData?.produtoAdubacao?.nome,
                          value: rowData?.produtoAdubacao?.id,
                        }}
                        onChange={handleSelectChange(
                          "produtoAdubacaoId",
                          index
                        )}
                        // onChange={setSelectedOption}
                        options={
                          configOptionsQueryAdubacao?.data?.produtoAdubacao
                        }
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Tipo</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        defaultValue={{
                          label: rowData?.tipoAdubacao?.nome,
                          value: rowData?.tipoAdubacao?.id,
                        }}
                        onChange={handleSelectChange("tipoAdubacaoId", index)}
                        // onChange={setSelectedOption}
                        options={configOptionsQueryAdubacao?.data?.tipoAdubacao}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Época</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        defaultValue={{
                          label: rowData?.epocaAdubacao?.nome,
                          value: rowData?.epocaAdubacao?.id,
                        }}
                        onChange={handleSelectChange("epocaAdubacaoId", index)}
                        options={
                          configOptionsQueryAdubacao?.data?.epocaAdubacao
                        }
                      />
                    </td>
                  </div>

                  <div className="column-configs-table">
                    <th className="correct-inline">Quantidade/ha (l ou Kg)</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        defaultValue={rowData?.quantidade}
                        onChange={handleInputChange("quantidade", index)}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Área</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        value={
                          rowData.area
                            ? rowData.area
                            : configuracaoGleba.defaultValues.area
                        }
                        onChange={handleInputChange("area", index)}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Taxa Variável</th>
                    <td>
                      <Select
                        menuPosition="fixed"
                        defaultValue={{
                          label: rowData.taxaVariavel ? "Sim" : "Não",
                          value: rowData.taxaVariavel,
                        }}
                        onChange={handleSelectChange("taxaVariavel", index)}
                        options={configOptionsQueryAdubacao?.data?.taxaVariavel}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">
                      Quantidade Total (l ou Kg)
                    </th>
                    <td>
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        disabled="true"
                        className="input-atributo"
                        type="number"
                        value={parseFloat(
                          rowData?.area * rowData?.quantidade ||
                            configuracaoGleba.defaultValues.area *
                              rowData?.quantidade
                        ).toFixed(2)}
                      />
                    </td>
                  </div>
                  <div className="column-configs-table">
                    <th className="correct-inline">Estádio</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="text"
                        value={rowData?.estadio}
                        onChange={handleInputChange("estadio", index, "text")}
                      />
                    </td>
                  </div>
                  <th>
                    <button
                      className="button-remove"
                      variant="danger"
                      onClick={() => handleRemoveRow(rowData.id)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </th>
                </tr>
              )
            )}
            <button
              className="button-add"
              variant="danger"
              onClick={() => handleAddRow()}
            >
              <IoMdAddCircleOutline />
            </button>
          </tbody>
        </table>
      ) : (
        <>
          <div className="no-data-info">Você não possui dados cadastrados.</div>
          <Button
            className="button-add1"
            variant="danger"
            onClick={() => handleAddRow()}
          >
            <IoMdAddCircleOutline />
          </Button>
        </>
      )}
    </div>
  );
}

export default Adubacao;
