import React, { useState, useEffect } from "react";

import Select from "react-select";
import "../../../PanelGerarRelatorios/index.css";
import { Button } from "rsuite";
import { useQuery } from "@tanstack/react-query";

import {
  getDowloadConfiguracaoExcel,
  getSafras,
  getTipoSafra,
} from "../../../../../Services/programation.service";

export const ExportarExcel = () => {
  const [selectOption, setSelectOption] = useState({
    tiposSafra: [],
    safra: [],
  });
  const [selectRelativeSafra, setSelecRelativeSafra] = useState("");
  const [selectRelativeTipoSafra, setSelecRelativeTipoSafra] = useState("");

  useEffect(() => {
    setSelecRelativeSafra(selectOption?.safra?.safraLabel);
    setSelecRelativeTipoSafra(selectOption?.tipoSafra?.tipoSafraLabel);
  }, [selectOption]);

  const getTipoSafrafetch = async () => {
    const { res } = await getTipoSafra();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((tipoSafra) => !tipoSafra.excluido)
        .map((tipoSafra) => ({
          label: tipoSafra.nome,
          value: tipoSafra.id,
        }));
    }
    return [];
  };

  const getSafrasfetch = async () => {
    const { res } = await getSafras();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((safra) => !safra.excluido)
        .map((safra) => ({
          label: safra.nome,
          value: safra.id,
        }));
    }
    return [];
  };

  const handleSelectChangeSafra = (name, label) => {
    return (option) => {
      let safra = safrasQuery.data;
      safra = {
        ...safra,
        [name]: option.value,
        [label]: option.label,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        safra,
      }));
    };
  };

  const handleSelectChangeTipoSafra = (name, label) => {
    return (option) => {
      let tipoSafra = tiposSafraQuery.data;
      tipoSafra = {
        ...tipoSafra,
        [name]: option.value,
        [label]: option.label,
      };
      setSelectOption((prevState) => ({
        ...prevState,
        tipoSafra,
      }));
    };
  };

  const tiposSafraQuery = useQuery(["tiposSafraQuery"], getTipoSafrafetch, []);
  const safrasQuery = useQuery(["safrasQuery"], getSafrasfetch, []);

  const handleDownloadExcel = async () => {
    const { res } = await getDowloadConfiguracaoExcel(
      selectOption.tipoSafra.tipoSafraId,
      selectOption.safra.safraId
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute(
      "download",
      "Safra_" + selectRelativeSafra + "_" + selectRelativeTipoSafra + ".xlsx"
    );

    link.click();

    URL.revokeObjectURL(url);
  };
  return (
    <div className="generating-container-reports">
      <div style={{ display: "flex", margin: "15px" }}>
        <div className="select-content-relatorio-agrupado">
          Selecione a Safra
          <Select
            defaultValue={{}}
            onChange={handleSelectChangeSafra("safraId", "safraLabel")}
            options={safrasQuery.data}
          />{" "}
        </div>
        <div className="select-content-relatorio-agrupado">
          Selecione o Tipo Safra
          <Select
            defaultValue={{}}
            onChange={handleSelectChangeTipoSafra(
              "tipoSafraId",
              "tipoSafraLabel"
            )}
            options={tiposSafraQuery.data}
          />
        </div>
      </div>{" "}
      <Button
        className="button-generate-reports"
        variant="danger"
        size="lg"
        onClick={handleDownloadExcel}
        style={{ marginLeft: "20px" }}
      >
        Gerar Planilha
      </Button>
    </div>
  );
};
