import Header from "../../Components/Header/Header";
import PanelGerarRelatorios from "./PanelGerarRelatorios";

function GerarRelatorios() {
  return (
    <div className="container-home">
      <Header />
      <PanelGerarRelatorios />
    </div>
  );
}

export default GerarRelatorios;
