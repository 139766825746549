import React, { useState, useContext } from "react";
import "./index.css";
import TratamentoSementes from "./Cards/TratamentoSementes";
import Sementes from "./Cards/Sementes";
import Adubacao from "./Cards/Adubacao";
import AduboFolhar from "./Cards/AduboFolhar";
import Herbicidas from "./Cards/Herbicidas";
import Fungicidas from "./Cards/Fungicidas";
import Inseticidas from "./Cards/Inseticidas";
import OutrosProdutos from "./Cards/OutrosProdutos";
import Observacoes from "./Cards/Observacoes";
import { ConfiguracaoGlebaContext } from "../../../../contexts/ConfiguracaoGlebaContext";
import { TiArrowSortedDown } from "react-icons/ti";
const AddCards = ({ data }) => {
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );
  const [showSementes, setShowSementes] = useState(
    !!data?.programationRelatorio
  );
  const [showTratamento, setShowTratamento] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.tratamentoSementes
  );

  const [showAdubacao, setShowAdubacao] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.adubacao
  );
  const [showAduboFolhar, setShowAduboFohar] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.adubacaoFoliar
  );
  const [showHerbicidas, setShowHerbicidas] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.herbicida
  );
  // const [showAduboFolhar, setShowAduboFohar] = useState(false);
  const [showFungicidas, setShowFungicidas] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.fungicida
  );

  const [showInseticidas, setShowInseticidas] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.inseticida
  );
  const [showOutros, setShowOutros] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.outrosProdutos
  );
  const [showObservacoes, setShowObservacoes] = useState(
    !!data.programationRelatorio?.culturas?.[0]?.observacoes
  );
  const handleButtonSementes = () => {
    setShowSementes(!showSementes);
  };
  const handleButtonTratamento = () => {
    setShowTratamento(!showTratamento);
  };
  const handleButtonAdubacao = () => {
    setShowAdubacao(!showAdubacao);
  };
  const handleButtonAduboFolhar = () => {
    setShowAduboFohar(!showAduboFolhar);
  };
  const handleButtonHerbicidas = () => {
    setShowHerbicidas(!showHerbicidas);
  };
  const handleButtonFungicidas = () => {
    setShowFungicidas(!showFungicidas);
  };
  const handleButtonInseticidas = () => {
    setShowInseticidas(!showInseticidas);
  };
  const handleButtonOutros = () => {
    setShowOutros(!showOutros);
  };
  const handleButtonObservacoes = () => {
    setShowObservacoes(!showObservacoes);
  };

  return (
    <>
      <div className="panel-buttons-cards">
        <div className="header-buttons-cards"> </div>
        <div className="button-primary-component">
          {showSementes === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonSementes}>
                {showSementes === false ? (
                  <div className="button-add-card-false">
                    <h4>Semente</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Semente</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonSementes}>
                {showSementes === false ? (
                  <div className="button-add-card-false">
                    <h4>Semente</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Semente</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
          {showTratamento === false ? (
            <div className="button-component-false">
              <button
                style={{ width: "249px" }}
                onClick={handleButtonTratamento}
              >
                {showTratamento === false ? (
                  <div className="button-add-card-false">
                    <h4>Tratamento de Semente</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Tratamento de Semente</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button
                style={{ width: "249px" }}
                onClick={handleButtonTratamento}
              >
                {showTratamento === false ? (
                  <div className="button-add-card-false">
                    <h4>Tratamento de Sementes</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Tratamento de Sementes</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
          {showAdubacao === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonAdubacao}>
                {showAdubacao === false ? (
                  <div className="button-add-card-false">
                    <h4>Adubação</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Adubação</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonAdubacao}>
                {showAdubacao === false ? (
                  <div className="button-add-card-false">
                    <h4>Adubação</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Adubação</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
          {showAduboFolhar === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonAduboFolhar}>
                {showAduboFolhar === false ? (
                  <div className="button-add-card-false">
                    <h4>Adubo Foliar</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Adubo Foliar</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonAduboFolhar}>
                {showAduboFolhar === false ? (
                  <div className="button-add-card-false">
                    <h4>Adubo Foliar</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Adubo Foliar</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
          {showHerbicidas === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonHerbicidas}>
                {showHerbicidas === false ? (
                  <div className="button-add-card-false">
                    <h4>Herbicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Herbicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonHerbicidas}>
                {showHerbicidas === false ? (
                  <div className="button-add-card-false">
                    <h4>Herbicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Herbicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
        </div>

        <div className="button-second-component">
          {showInseticidas === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonInseticidas}>
                {showInseticidas === false ? (
                  <div className="button-add-card-false">
                    <h4>Inseticidas</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Inseticidas</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonInseticidas}>
                {showInseticidas === false ? (
                  <div className="button-add-card-false">
                    <h4>Inseticidas</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Inseticidas</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
          {showFungicidas === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonFungicidas}>
                {showFungicidas === false ? (
                  <div className="button-add-card-false">
                    <h4>Fungicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Fungicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonFungicidas}>
                {showFungicidas === false ? (
                  <div className="button-add-card-false">
                    <h4>Fungicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Fungicidas</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
          {showOutros === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonOutros}>
                {showOutros === false ? (
                  <div className="button-add-card-false">
                    <h4>Outros Produtos</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Outros Produtos</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonOutros}>
                {showOutros === false ? (
                  <div className="button-add-card-false">
                    <h4>Outros Produtos</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Outros Produtos</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}{" "}
          {showObservacoes === false ? (
            <div className="button-component-false">
              <button onClick={handleButtonObservacoes}>
                {showObservacoes === false ? (
                  <div className="button-add-card-false">
                    <h4>Observações</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Observações</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div className="button-component">
              <button onClick={handleButtonObservacoes}>
                {showObservacoes === false ? (
                  <div className="button-add-card-false">
                    <h4>Observações</h4>
                    <TiArrowSortedDown />
                  </div>
                ) : (
                  <div className="button-add-card">
                    <h4>Observações</h4>
                    <TiArrowSortedDown />
                  </div>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="panel-cards">
        {showSementes && (
          <div>
            <Sementes data={data} />
          </div>
        )}
        {showTratamento && (
          <div>
            <TratamentoSementes data={data} />
          </div>
        )}
        {showAdubacao && (
          <div>
            <Adubacao />
          </div>
        )}
        {showAduboFolhar && (
          <div>
            <AduboFolhar />
          </div>
        )}
        {showHerbicidas && (
          <div>
            <Herbicidas />
          </div>
        )}
        {showInseticidas && (
          <div>
            <Inseticidas />
          </div>
        )}
        {showFungicidas && (
          <div>
            <Fungicidas />
          </div>
        )}

        {showOutros && (
          <div>
            <OutrosProdutos />
          </div>
        )}
        {showObservacoes && (
          <div>
            <Observacoes />
          </div>
        )}
      </div>
    </>
  );
};

export default AddCards;
