import { createContext, useState } from "react";

export const ConfiguracaoGlebaContext = createContext(null);

const ConfiguracaoGlebaProvider = ({ children }) => {
  const [configuracaoGleba, setConfiguracaoGleba] = useState({
    tipoSafra: "",
    safra: "",
    gleba: "",
    observacoes: "",
  });
  const handleConfiguracaoGleba = (name, value) => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSelectChange = (name) => {
    return (option) => {
      handleConfiguracaoGleba(name, option.value);
    };
  };
  return (
    <ConfiguracaoGlebaContext.Provider
      value={{
        configuracaoGleba,
        setConfiguracaoGleba,
        handleConfiguracaoGleba,
        handleSelectChange,
      }}
    >
      {children}
    </ConfiguracaoGlebaContext.Provider>
  );
};

export default ConfiguracaoGlebaProvider;
