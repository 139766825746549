import React, { useContext } from "react";
import Select from "react-select";

import "./index.css";

import {
  getSementesCultivar,
  getSementesProposito,
} from "../../../../../../Services/programation.service";
import { useQuery } from "@tanstack/react-query";
import { ConfiguracaoGlebaContext } from "../../../../../../contexts/ConfiguracaoGlebaContext";

function Sementes() {
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );

  const sementesConfigurationFetch = async () => {
    const {
      res: { data: propositosData },
    } = await getSementesProposito();
    const culturaId = configuracaoGleba.culturas?.[0].culturaId;
    const {
      res: { data: cultivaresData },
    } = await getSementesCultivar(culturaId);
    return {
      propositos: propositosData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      cultivares: cultivaresData
        .filter((item) => !item.excluido)
        .map((item) => ({
          label: item.nome,
          value: item.id,
        })),
      sementePropria: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
      taxaVariavel: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
      sementePorSaca: [
        {
          label: "60mil",
          value: 60000,
        },
        {
          label: "80mil",
          value: 80000,
        },
      ],
    };
  };

  const configOptionsQuery = useQuery(
    ["configOptionsQuery", configuracaoGleba?.culturas],
    sementesConfigurationFetch,
    {
      propositos: [],
      cultivares: [],
      sementePropria: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
      sementePorSaca: [
        {
          label: "60mil",
          value: 60000,
        },
        {
          label: "80mil",
          value: 80000,
        },
      ],
    }
  );

  const handleSelectChange = (name) => {
    return (option) => {
      const culturas = configuracaoGleba.culturas;
      culturas[0] = {
        ...culturas[0],
        [name]: option.value,
      };
      if (name === "cultivarId") {
        culturas[0] = {
          ...culturas[0],
          cultivar: {
            ...culturas[0].cultivar,
            nome: option.label,
            id: option.value,
          },
        };
      }
      if (name === "propositoId") {
        culturas[0] = {
          ...culturas[0],
          proposito: {
            ...culturas[0].cultivar,
            nome: option.label,
            id: option.value,
          },
        };
      }
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };

  const handleInputChange = (name) => {
    return (e) => {
      const { value } = e.target;
      const culturas = configuracaoGleba.culturas;
      culturas[0] = {
        ...culturas[0],
        [name]: value,
      };
      setConfiguracaoGleba((prevState) => ({
        ...prevState,
        culturas,
      }));
    };
  };
  return (
    <div className="table-responsive">
      <div className="title-card">SEMENTE</div>
      {configuracaoGleba?.culturas?.length !== 0 ? (
        <table className="table">
          <tbody style={{ width: "100%", marginBottom: "0px" }}>
            {(configuracaoGleba?.culturas || []).map((rowData, index) => (
              <tr
                style={{
                  display: "flex",
                  width: "max-content",
                  boxShadow: " 0px -4px 12px -1px rgba(0,0,0,0.45);",
                  margin: "10px",
                  // border: "solid 2px #dfdfdf",
                  borderRadius: "6px",
                  padding: "5px",
                  background: "rgba(1, 104, 32, 0.1)",
                }}
                key={index}
              >
                <div className="column-configs-table">
                  <th className="correct-inline">Cultivar</th>
                  <td>
                    <Select
                      menuPosition="fixed"
                      value={{
                        label: rowData?.cultivar?.nome,
                        value: rowData?.cultivar?.id,
                      }}
                      onChange={handleSelectChange("cultivarId")}
                      options={configOptionsQuery?.data?.cultivares}
                    />
                  </td>
                </div>

                <div className="column-configs-table">
                  <th className="correct-inline">Propósito</th>
                  <td>
                    <Select
                      menuPosition="fixed"
                      value={{
                        label: rowData?.proposito?.nome,
                        value: rowData?.proposito?.id,
                      }}
                      onChange={handleSelectChange("propositoId")}
                      // onChange={setSelectedOption}
                      options={configOptionsQuery?.data?.propositos}
                    />
                  </td>
                </div>
                <div className="column-configs-table">
                  <th className="correct-inline">Ordem de plantio</th>
                  <td>
                    <input
                      className="input-atributo"
                      type="number"
                      value={rowData.ordem}
                      onChange={handleInputChange("ordem")}
                    />
                  </td>
                </div>
                <div className="column-configs-table">
                  <th className="correct-inline">Semente Própria</th>
                  {}
                  <td>
                    <Select
                      menuPosition="fixed"
                      defaultValue={{
                        label: rowData.sementePropria ? "Sim" : "Não",
                        value: rowData.sementePropria,
                      }}
                      onChange={handleSelectChange("sementePropria")}
                      options={configOptionsQuery?.data?.sementePropria}
                    />
                  </td>
                </div>
                {configuracaoGleba.culturas[0]?.cultura?.isSementePorSaca ===
                  false &&
                configuracaoGleba.culturas[0]?.cultura?.isPms === false ? (
                  ""
                ) : (
                  <div className="column-configs-table">
                    <th className="correct-inline">Quantidade/m²</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        value={rowData.quantidade}
                        onChange={handleInputChange("quantidade")}
                      />
                    </td>
                  </div>
                )}
                {configuracaoGleba.culturas[0]?.cultura?.isSementePorSaca ===
                false ? (
                  configuracaoGleba.culturas[0]?.cultura?.isPms ? (
                    <div className="column-configs-table">
                      <th className="correct-inline">Kg/ha</th>
                      <td>
                        <input
                          style={{
                            background: "rgba(1, 104, 32, 0.1)",
                            fontWeight: "bold",
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                          className="input-atributo"
                          type="number"
                          disabled={true}
                          value={parseFloat(
                            (rowData.quantidade * 10000 * rowData.pms) / 1000
                          ).toFixed(2)}
                          onChange={handleInputChange("kgHa")}
                        />
                      </td>
                    </div>
                  ) : (
                    <div className="column-configs-table">
                      <th className="correct-inline">Kg/ha</th>
                      <td>
                        <input
                          className="input-atributo"
                          type="number"
                          value={rowData.kgHa}
                          onChange={handleInputChange("kgHa")}
                        />
                      </td>
                    </div>
                  )
                ) : (
                  ""
                )}

                {configuracaoGleba.culturas[0]?.cultura?.isSementePorSaca ? (
                  <>
                    <div className="column-configs-table">
                      <th className="correct-inline">Plantas/ha</th>
                      <td>
                        <input
                          style={{
                            background: "rgba(1, 104, 32, 0.1)",
                            fontWeight: "bold",
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                          className="input-atributo"
                          type="number"
                          disabled="true"
                          value={rowData.quantidade * 10000}
                        />
                      </td>
                    </div>
                    <div className="column-configs-table">
                      <th className="correct-inline">Semente/saca</th>
                      <td>
                        <Select
                          menuPosition="fixed"
                          value={{
                            label: rowData.sementePorSaca,
                            //   ? rowData.sementePorSaca === 60000
                            //     ? "60mil"
                            //     : "80mil"
                            //   : "60mil",
                            value: 60000,
                          }}
                          onChange={handleSelectChange("sementePorSaca")}
                          options={configOptionsQuery?.data?.sementePorSaca}
                        />
                      </td>
                    </div>
                    <div className="column-configs-table">
                      <th className="correct-inline">Saca/ha</th>
                      <td>
                        <input
                          style={{
                            background: "rgba(1, 104, 32, 0.1)",
                            fontWeight: "bold",
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                          className="input-atributo"
                          disabled="true"
                          type="number"
                          value={parseFloat(
                            (rowData.quantidade * 10000) /
                              (rowData.sementePorSaca || 60000)
                          ).toFixed(2)}
                          onChange={handleInputChange("sacaPorHa")}
                        />
                      </td>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {configuracaoGleba.culturas[0]?.cultura?.isPms ? (
                  <div className="column-configs-table">
                    <th className="correct-inline">PMS</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        value={rowData.pms}
                        onChange={handleInputChange("pms")}
                      />
                    </td>
                  </div>
                ) : (
                  ""
                )}

                <div className="column-configs-table">
                  <th className="correct-inline">Taxa Variável</th>
                  {}
                  <td>
                    <Select
                      menuPosition="fixed"
                      defaultValue={{
                        label: rowData.taxaVariavel ? "Sim" : "Não",
                        value: rowData.taxaVariavel,
                      }}
                      onChange={handleSelectChange("sementePropria")}
                      options={configOptionsQuery?.data?.taxaVariavel}
                    />
                  </td>
                </div>
                {configuracaoGleba.culturas[0]?.cultura?.isPms ||
                configuracaoGleba.culturas[0]?.cultura?.isSementePorSaca ? (
                  <div className="column-configs-table">
                    <th className="correct-inline">Planta por metro Lin.</th>
                    <td>
                      <input
                        className="input-atributo"
                        type="number"
                        disabled={true}
                        value={parseFloat(
                          rowData.quantidade / (1 / rowData.espaco)
                        ).toFixed(2)}
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        onChange={handleInputChange("area")}
                      />
                    </td>
                  </div>
                ) : (
                  ""
                )}

                <div className="column-configs-table">
                  <th className="correct-inline">Espaçamento</th>
                  <td>
                    <input
                      className="input-atributo"
                      type="number"
                      value={rowData.espaco}
                      onChange={handleInputChange("espaco")}
                      disabled={false}
                      style={{
                        // background: "rgba(1, 104, 32, 0.1)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                      }}
                    />
                  </td>
                </div>
                <div className="column-configs-table">
                  <th className="correct-inline">Área (ha)</th>
                  <td>
                    <input
                      className="input-atributo"
                      type="number"
                      disabled={false}
                      value={
                        rowData.area
                          ? rowData.area
                          : configuracaoGleba.defaultValues.area
                      }
                      // value={
                      //   rowData.area
                      //     ? rowData.area
                      //     : configuracaoGleba.gleba.area
                      // }
                      style={{
                        // background: "rgba(1, 104, 32, 0.1)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                      }}
                      onChange={handleInputChange("area")}
                    />
                  </td>
                </div>
                <div className="column-configs-table">
                  <th className="correct-inline">Quantidade total </th>
                  <td>
                    {configuracaoGleba.culturas[0]?.cultura
                      ?.isSementePorSaca !== false ? (
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        className="input-atributo"
                        type="number"
                        disabled="true"
                        value={parseFloat(
                          rowData.area
                            ? rowData.area *
                                ((rowData.quantidade * 10000) /
                                  (rowData.sementePorSaca || 60000))
                            : configuracaoGleba.defaultValues.area *
                                ((rowData.quantidade * 10000) /
                                  (rowData.sementePorSaca || 60000))
                        ).toFixed(2)}
                      />
                    ) : (
                      ""
                    )}
                    {configuracaoGleba.culturas[0]?.cultura
                      ?.isSementePorSaca === false &&
                    configuracaoGleba.culturas[0]?.cultura?.isPms === false ? (
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        className="input-atributo"
                        type="number"
                        disabled="true"
                        value={parseFloat(
                          rowData.area
                            ? rowData.area * rowData.kgHa
                            : configuracaoGleba.defaultValues.area *
                                rowData.kgHa
                        ).toFixed(2)}
                      />
                    ) : (
                      ""
                    )}
                    {configuracaoGleba.culturas[0]?.cultura?.isPms !== false ? (
                      <input
                        style={{
                          background: "rgba(1, 104, 32, 0.1)",
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        className="input-atributo"
                        type="number"
                        disabled="true"
                        value={parseFloat(
                          rowData.area
                            ? (rowData.area *
                                (rowData.quantidade * 10000 * rowData.pms)) /
                                1000
                            : (configuracaoGleba.defaultValues.area *
                                (rowData.quantidade * 10000 * rowData.pms)) /
                                1000
                        ).toFixed(2)}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </div>
                <div className="column-configs-table">
                  <th className="correct-inline">Data de Plantio</th>
                  <td>
                    <input
                      className="input-atributo"
                      type="text"
                      value={rowData.dataRealizacao}
                      onChange={handleInputChange("dataRealizacao")}
                      disabled={false}
                      style={{
                        // background: "rgba(1, 104, 32, 0.1)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                      }}
                    />
                  </td>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <div className="no-data-info">Você não possui dados cadastrados.</div>
        </>
      )}
    </div>
  );
}

export default Sementes;
