import Header from "../../Components/Header/Header";
import PanelClonarGleba from "./PanelClonarGleba";

function ClonarGleba() {
  return (
    <div className="container-home">
      <Header />
      <PanelClonarGleba />
    </div>
  );
}

export default ClonarGleba;
