import {
  Modal,
  Button,
  CheckPicker,
  SelectPicker,
  Input,
  Tag,
  TagGroup,
  Header,
} from "rsuite";
import React, { useState } from "react";
import {
  createProdutoTratamentoSetementes,
  createClasseTratamentoSementes,
  getTratamentoProduto,
  createSimilarTratamentoSementes,
  getTratamentoClasse,
  deleteProdutoTratamentoSementes,
  editProdutoTratamentoSementes,
  deleteClasseTratamentoSementes,
  editClasseTratamentoSementes,
} from "../../../../../Services/programation.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { RiEdit2Fill } from "react-icons/ri";

import "./index.css";
const ModalTratamentoSementes = (props) => {
  const [produto, setProduto] = useState("");
  const [classeTratamento, setClasseTratamento] = useState("");

  const [selectOption, setSelectOption] = useState({
    produto: "",
    similar: [],
  });

  const tagList = () => {
    return selectOption.similar.map((similar) => ({
      label: produtoQueryTratamentoSementes.data.find(
        (produto) => produto.value === similar
      ).label,
      value: similar,
    }));
  };

  const getTratamentoSementesProdutoFetch = async () => {
    const { res } = await getTratamentoProduto();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produto) => !produto.excluido)
        .map((produto) => ({
          label: produto.nome,
          value: produto.id,
        }));
    }
    return [];
  };
  const handleSelectSimilaresChange = (value) => {
    setSelectOption((prevState) => ({
      ...prevState,
      similar: value,
    }));
  };
  const handleSelectProdutoChange = (value) => {
    setSelectOption((prevState) => ({
      ...prevState,
      produto: value,
    }));
  };
  const handleSubmit = async (e) => {
    if (
      produto.length <= 0 &&
      classeTratamento.length <= 0 &&
      !selectOption.produto &&
      !selectOption.similar.length
    ) {
      toast.warning(
        "Nenhum item foi adicionado ao formulario. Adicione pelo menos um item para continuar!"
      );
    } else {
      if (produto.length >= 1) {
        const { res } = await createProdutoTratamentoSetementes({
          nome: produto,
        });
        if (res) {
          handleButtonCloseModal();
          toast.success("Produto adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o Produto!");
        }
      }
      if (classeTratamento.length >= 1) {
        const { res } = await createClasseTratamentoSementes({
          nome: classeTratamento,
        });
        if (res) {
          handleButtonCloseModal();
          toast.success("Classe adicionada com sucesso!");
        } else {
          toast.error("Erro ao adicionar o classe!");
        }
      }
    }
    if (selectOption.produto && selectOption.similar.length <= 0) {
      toast.warning("Selecione ao  menos um (1) similar para continuar!");
    }
    if (selectOption.produto && selectOption.similar.length) {
      const body = {
        produtoTratamentoId: selectOption.produto,
        produtoTratamentoSimilaresId: selectOption.similar,
      };
      const { res } = await createSimilarTratamentoSementes(body);
      if (res) {
        toast.success("Similar adicionado com sucesso!");
        handleButtonCloseModal();
      } else {
        toast.error("Erro ao adicionar o Produto!");
      }
    }
  };
  const removeTag = (tag) => {
    const filterSimilar = selectOption.similar.filter(
      (similar) => similar !== tag.value
    );
    setSelectOption((prevState) => ({
      ...prevState,
      similar: filterSimilar,
    }));
  };

  const handleButtonCloseModal = () => {
    setProduto("");
    setClasseTratamento("");
    setSelectOption({
      produto: "",
      similar: [],
    });
    setSelectOptionEdit(null);
    props.closeModalTratamentoSementes();
  };

  const data = [
    { value: 1, label: "Produto" },
    { value: 2, label: "Classe" },
  ];
  const handleEditProduto = async () => {
    const produtoId = selectedEditProduto?.value;
    const { res } = await editProdutoTratamentoSementes(
      { nome: produtoEditProduto },
      produtoId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditProduto();
      toast.success("Produto editado com sucesso!");
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao editar produto!");
      closeModalEditProduto();
    }
  };
  const handleDeleteProduto = async () => {
    const epocaId = selectedEditProduto?.value;
    const { res } = await deleteProdutoTratamentoSementes(epocaId);
    if (res?.data) {
      toast.success("Produto excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao excluir produto!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOptionEdit(null);
    }
  };

  const handleEditClasse = async () => {
    const classeId = selectedEditClasse?.value;
    const { res } = await editClasseTratamentoSementes(
      { nome: classeEditClasse },
      classeId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditClasse();
      toast.success("Classe editada com sucesso!");
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao editar classe!");
      closeModalEditClasse();
    }
  };
  const handleDeleteClasse = async () => {
    const classeId = selectedEditClasse?.value;
    const { res } = await deleteClasseTratamentoSementes(classeId);
    if (res?.data) {
      toast.success("Classe excluida com sucesso!");
      handleButtonCloseModal();
      closeModalEditClasse();
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao excluir classe!");
      handleButtonCloseModal();
      closeModalEditClasse();
      setSelectOptionEdit(null);
    }
  };
  const [selectOptionEdit, setSelectOptionEdit] = useState(null);

  const [showModalEditProduto, setShowModalEditProduto] = useState(false);
  const [showModalEditClasse, setShowModalEditClasse] = useState(false);

  const [selectedEditProduto, setSelectedEditProduto] = useState({});
  const [selectedEditClasse, setSelectedEditClasse] = useState({});

  const [produtoEditProduto, setProdutoEditProduto] = useState("");
  const [classeEditClasse, setClasseEditClasse] = useState("");

  const getTratamentoSementesProdutoEditFetch = async () => {
    if (selectOptionEdit === 1) {
      const { res } = await getTratamentoProduto();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((produto) => !produto.excluido)
          .map((produto) => ({
            label: produto.nome,
            value: produto.id,
          }));
      }
      return [];
    }
  };
  const getTratamentoClasseFetch = async () => {
    if (selectOptionEdit === 2) {
      const { res } = await getTratamentoClasse();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((classe) => !classe.excluido)
          .map((classe) => ({
            label: classe.nome,
            value: classe.id,
          }));
      }
    }
    return [];
  };

  const openModalEditProduto = (produto) => {
    setSelectedEditProduto(produto);
    setShowModalEditProduto(true);
  };
  const closeModalEditProduto = () => {
    setShowModalEditProduto(false);
  };

  const openModalEditClasse = (classe) => {
    setSelectedEditClasse(classe);
    setShowModalEditClasse(true);
  };
  const closeModalEditClasse = () => {
    setShowModalEditClasse(false);
  };
  const handleSelectChangeEdit = (value) => {
    setSelectOptionEdit(value);
  };
  const produtoQueryTratamentoSementes = useQuery(
    ["produtoQueryTratamentoSementes", produto, selectOptionEdit],
    getTratamentoSementesProdutoFetch,
    {
      initialData: [],
    }
  );
  const produtoQueryTratamentoSementesEdit = useQuery(
    ["getTratamentoSementesProdutoEditFetch", selectOptionEdit],
    getTratamentoSementesProdutoEditFetch,
    []
  );
  const classeQueryTratamentoSementesEdit = useQuery(
    ["getTratamentoClasseFetch", selectOptionEdit],
    getTratamentoClasseFetch,
    []
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={props.showModalTratamentoSementes}
        onClose={props.closeModalTratamentoSementes}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Tratamento de Sementes</Modal.Header>

        <Modal.Body>
          <div className="input-cadastro-column">
            <Header>
              <h5>Produto</h5>
            </Header>

            <Input
              onChange={(e) => setProduto(e)}
              value={produto}
              disabled={selectOption.produto || selectOption.similar.length}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Produto"
            />
          </div>
          <div className="input-cadastro-column">
            <Header>
              <h5>Classe</h5>
            </Header>
            <Input
              value={classeTratamento}
              onChange={(e) => setClasseTratamento(e)}
              className="input-cadastro"
              style={{ width: 300 }}
              disabled={selectOption.produto || selectOption.similar.length}
              placeholder="Insira a Classe"
            />
          </div>
          <div className="produto-similar-content">
            <div className="select-picker-produto-similar-similar">
              <SelectPicker
                placeholder={"Selecione o produto"}
                onChange={handleSelectProdutoChange}
                data={produtoQueryTratamentoSementes?.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                }}
                disabled={!!produto || classeTratamento.length >= 1}
              />
            </div>
            <div className="select-picker-produto-similar-similar">
              <CheckPicker
                disabled={
                  !!produto ||
                  classeTratamento.length >= 1 ||
                  !selectOption.produto
                }
                placeholder={"Selecione os similares"}
                data={produtoQueryTratamentoSementes?.data}
                searchable={false}
                onChange={handleSelectSimilaresChange}
                value={selectOption.similar}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                }}
              />
              <TagGroup style={{ margin: "10px 15px 10px 0" }}>
                {tagList().map((tag) => (
                  <Tag
                    closable
                    onClose={() => removeTag(tag)}
                    color="green"
                  >
                    {tag.label}
                  </Tag>
                ))}
              </TagGroup>
            </div>
          </div>{" "}
          <SelectPicker
            onChange={handleSelectChangeEdit}
            value={selectOptionEdit}
            data={data}
            disabled={selectOption.produto || !!produto || !!classeTratamento}
            placeholder="Selecione para editar ou excluir"
            style={{
              width: 250,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          {selectOption?.produto || !!classeTratamento || !!produto ? (
            ""
          ) : (
            <TagGroup style={{ margin: "10px 15px 0 0" }}>
              {produtoQueryTratamentoSementesEdit?.data?.map((produto) => (
                <Tag
                  style={{ cursor: "pointer", paddingRight: "5px" }}
                  onClick={() => openModalEditProduto(produto)}
                  color="green"
                >
                  {produto?.label} <RiEdit2Fill />
                </Tag>
              ))}
              {classeQueryTratamentoSementesEdit?.data?.map((classe) => (
                <Tag
                  style={{ cursor: "pointer", paddingRight: "5px" }}
                  onClick={() => openModalEditClasse(classe)}
                  color="green"
                >
                  {classe?.label} <RiEdit2Fill />
                </Tag>
              ))}
            </TagGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={handleButtonCloseModal}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditProduto}
        onClose={closeModalEditProduto}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Produto</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setProdutoEditProduto(e)}
            className="input-cadastro"
            defaultValue={selectedEditProduto?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova época"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditProduto}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteProduto}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditProduto}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditClasse}
        onClose={closeModalEditClasse}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Classe</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setClasseEditClasse(e)}
            className="input-cadastro"
            defaultValue={selectedEditClasse?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova época"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditClasse}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteClasse}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditClasse}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalTratamentoSementes;
