import {
  Modal,
  Button,
  CheckPicker,
  Tag,
  TagGroup,
  SelectPicker,
  Input,
  Header,
} from "rsuite";
import React, { useState } from "react";
import {
  createOutrosProdutos,
  getProdutoOutrosProdutos,
  createSimilarOutrosProdutos,
  deleteProdutoOutrosProdutos,
  editProdutoOutrosProdutos,
} from "../../../../../Services/programation.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { RiEdit2Fill } from "react-icons/ri";

import "./index.css";
const ModalOutrosProdutos = (props) => {
  const [produto, setProduto] = useState("");
  const [selectOption, setSelectOption] = useState({
    produto: "",
    similar: [],
  });
  const handleSubmit = async (e) => {
    // Primeira requisição POST para a rota 'rotaNome'
    if (
      produto.length <= 0 &&
      !selectOption.produto &&
      !selectOption.similar.length
    ) {
      toast.warning(
        "Nenhum item foi adicionado ao formulario. Adicione pelo menos um item para continuar!"
      );
    } else {
      if (produto.length >= 1) {
        const { res } = await createOutrosProdutos({ nome: produto });
        if (res) {
          handleButtonCloseModal();
          toast.success("Produto adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o Produto!");
        }
      }
    }
    if (selectOption.produto && selectOption.similar.length <= 0) {
      toast.warning("Selecione ao  menos um (1) similar para continuar!");
    }

    if (selectOption.produto && selectOption.similar.length) {
      const body = {
        produtoOutrosProdutosId: selectOption.produto,
        produtoOutrosProdutosSimilaresId: selectOption.similar,
      };
      const { res } = await createSimilarOutrosProdutos(body);

      if (res) {
        toast.success("Similar adicionado com sucesso!");
        handleButtonCloseModal();
      } else {
        toast.error("Erro ao adicionar o Similar!");
      }
    }
  };
  const handleSelectSimilaresChange = (value) => {
    setSelectOption((prevState) => ({
      ...prevState,
      similar: value,
    }));
  };
  const handleSelectProdutoChange = (value) => {
    setSelectOption((prevState) => ({
      ...prevState,
      produto: value,
    }));
  };

  const getOutrosProdutoProdutoFetch = async () => {
    const { res } = await getProdutoOutrosProdutos();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produto) => !produto.excluido)
        .map((produto) => ({
          label: produto.nome,
          value: produto.id,
        }));
    }
    return [];
  };

  const tagList = () => {
    return selectOption?.similar?.map((similar) => ({
      label: produtoQueryOutrosProdutos?.data?.find(
        (produto) => produto?.value === similar
      )?.label,
      value: similar,
    }));
  };

  const removeTag = (tag) => {
    const filterSimilar = selectOption.similar.filter(
      (similar) => similar !== tag.value
    );
    setSelectOption((prevState) => ({
      ...prevState,
      similar: filterSimilar,
    }));
  };
  const handleButtonCloseModal = () => {
    setProduto("");
    setSelectOption({
      produto: "",
      similar: [],
    });
    props.closeModalOutrosProdutos();
  };
  const [showModalEditProduto, setShowModalEditProduto] = useState(false);
  const [produtoEditProduto, setProdutoEditProduto] = useState("");
  const [selectedEditProduto, setSelectedEditProduto] = useState({});

  const openModalEditProduto = (produto) => {
    setSelectedEditProduto(produto);
    setShowModalEditProduto(true);
  };

  const closeModalEditProduto = () => {
    setShowModalEditProduto(false);
  };
  const handleEditProduto = async () => {
    const produtoId = selectedEditProduto?.value;

    const { res } = await editProdutoOutrosProdutos(
      { nome: produtoEditProduto },
      produtoId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditProduto();
      toast.success("Produto editado com sucesso!");
      setSelectOption(null);
    } else {
      toast.error("Erro ao editar produto!");
    }
  };
  const handleDeleteProduto = async () => {
    const produtoId = selectedEditProduto?.value;
    const { res } = await deleteProdutoOutrosProdutos(produtoId);
    if (res?.data) {
      toast.success("Produto excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOption(null);
    } else {
      toast.error("Erro ao excluir produto!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOption(null);
    }
  };
  const produtoQueryOutrosProdutos = useQuery(
    ["produtoQueryOutrosProdutos", produto, showModalEditProduto],
    getOutrosProdutoProdutoFetch,
    []
  );
  return (
    <>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={props.showModalOutrosProdutos}
        onClose={props.closeModalOutrosProdutos}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Outros Produtos</Modal.Header>

        <Modal.Body>
          <div className="input-cadastro-column">
            <Header>
              <h5>Produto</h5>
            </Header>

            <Input
              onChange={(e) => setProduto(e)}
              value={produto}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Produto"
              disabled={selectOption?.produto || selectOption?.similar?.length}
            />
          </div>
          <div className="produto-similar-content">
            <div className="select-picker-produto-similar-similar">
              <SelectPicker
                disabled={!!produto}
                onChange={handleSelectProdutoChange}
                placeholder={"Selecione o produto"}
                data={produtoQueryOutrosProdutos.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                }}
              />{" "}
            </div>
            <div className="select-picker-produto-similar-similar">
              <CheckPicker
                placeholder={"Selecione os similares"}
                disabled={!!produto || !selectOption?.produto}
                data={produtoQueryOutrosProdutos?.data}
                searchable={false}
                onChange={handleSelectSimilaresChange}
                value={selectOption?.similar}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                }}
              />
              <TagGroup style={{ margin: "10px 15px 0 0" }}>
                {tagList()?.map((tag) => (
                  <Tag
                    closable
                    onClose={() => removeTag(tag)}
                    color="green"
                  >
                    {" "}
                    {tag?.label}{" "}
                  </Tag>
                ))}
              </TagGroup>
            </div>
          </div>{" "}
          {selectOption?.produto || !!produto ? (
            ""
          ) : (
            <TagGroup style={{ margin: "10px 15px 0 0" }}>
              {produtoQueryOutrosProdutos?.data?.map((produto) => (
                <Tag
                  style={{ cursor: "pointer", paddingRight: "5px" }}
                  onClick={() => openModalEditProduto(produto)}
                  color="green"
                >
                  {produto?.label} <RiEdit2Fill />
                </Tag>
              ))}
            </TagGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={handleButtonCloseModal}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditProduto}
        onClose={closeModalEditProduto}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Produto</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setProdutoEditProduto(e)}
            className="input-cadastro"
            defaultValue={selectedEditProduto?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova época"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditProduto}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteProduto}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditProduto}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalOutrosProdutos;
