import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import CadastroInsumos from "../Pages/CadastroInsumos/CadastroInsumos";
import GerarRelatorios from "../Pages/GerarRelatorios/GerarRelatorios";
import ClonarGleba from "../Pages/ClonarGleba/ClonarGleba";
import CadastroProdutor from "../Pages/CadastrarProdutor/CadastrarProdutor";
import Login from "../Pages/Login/Login";
import ConfiguracaoGlebaProvider from "../contexts/ConfiguracaoGlebaContext";

function Routers() {
  return (
    <Router>
      <Routes>
        <Route
          path="/Home"
          element={
            <ConfiguracaoGlebaProvider>
              <Home />
            </ConfiguracaoGlebaProvider>
          }
        />
        <Route
          path="/"
          element={<Login />}
        />
        <Route
          path="/CadastroInsumos"
          element={<CadastroInsumos />}
        />
        <Route
          path="/GerarRelatorios"
          element={<GerarRelatorios />}
        />
        <Route
          path="/ClonarGleba"
          element={<ClonarGleba />}
        />
        <Route
          path="/CadastroProdutor"
          element={<CadastroProdutor />}
        />
      </Routes>
    </Router>
  );
}

export default Routers;
