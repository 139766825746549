import api from "./api";

export const getProgramationGleba = async () => {
  try {
    const res = await api.get("teste");
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const getCulturas = async (id) => {
  try {
    const res = await api.get(`cultura`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getConfiguracao = async () => {
  try {
    const res = await api.get(`configuraca`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getConfiguracaoGleba = async () => {
  try {
    const res = await api.get(`configuracaoGleba`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const getConfiguracaRelatorio = async (data) => {
  try {
    const res = await api.get(
      `configuracaoGleba/${data.glebaId}/${data.safraId}/${data.tipoSafraId}`
    );
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const getTipoSafra = async () => {
  try {
    const res = await api.get(`tipoSafra`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getSafras = async () => {
  try {
    const res = await api.get(`safra`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getGlebas = async (fazendaId) => {
  try {
    const res = await api.get(`gleba?fazendaId=${fazendaId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const getSementesCultivar = async (culturaId) => {
  try {
    const res = await api.get(`cultivar?culturaId=${culturaId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getSementesProposito = async () => {
  try {
    const res = await api.get(`proposito`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getCultura = async () => {
  try {
    const res = await api.get(`cultura`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getTratamentoProduto = async () => {
  try {
    const res = await api.get(`produtoTratamento`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getTratamentoClasse = async () => {
  try {
    const res = await api.get(`classeTratamento`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const createGleba = async (body) => {
  try {
    const res = await api.post(`configuracaoGleba`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutoAdubacao = async () => {
  try {
    const res = await api.get(`produtoAdubacao`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getTipoAdubacao = async () => {
  try {
    const res = await api.get(`tipoAdubacao`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getEpocaAdubacao = async () => {
  try {
    const res = await api.get(`epocaAdubacao`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutoAdubacaoFolhar = async () => {
  try {
    const res = await api.get(`produtoAdubacaoFoliar`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutoHerbicida = async () => {
  try {
    const res = await api.get(`produtoHerbicida`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const getTipoHerbicida = async () => {
  try {
    const res = await api.get(`tipoHerbicida`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutoInseticida = async () => {
  try {
    const res = await api.get(`produtoInseticida`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutoFungicida = async () => {
  try {
    const res = await api.get(`produtoFungicida`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutoOutrosProdutos = async () => {
  try {
    const res = await api.get(`produtoOutrosProdutos`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createCultura = async (body) => {
  try {
    const res = await api.post(`cultura`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProposito = async (body) => {
  try {
    const res = await api.post(`proposito`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createCultivar = async (body) => {
  try {
    const res = await api.post(`cultivar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutores = async () => {
  try {
    const res = await api.get(`produtor`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getFazendas = async () => {
  try {
    const res = await api.get(`fazenda`);

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getDowloadConfiguracaoPorGleba = async (
  tipoSafraId,
  safraId,
  fazendaId
) => {
  try {
    const res = await api.get(
      `configuracaoGleba/downloadConfiguracaoPorGleba/${safraId}/${tipoSafraId}/${fazendaId}`,
      { responseType: "blob" }
    );
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getDowloadConfiguracaoAgrupada = async (
  tipoSafraId,
  safraId,
  fazendaId,
  culturaId,
  grupoId,
  produtorId
) => {
  let queryparams = {};
  if (grupoId === 1) {
    queryparams = { fazendaId, safraId, tipoSafraId };
  }
  if (grupoId === 2) {
    queryparams = { culturaId, fazendaId, safraId, tipoSafraId };
  }
  if (grupoId === 3) {
    queryparams = { produtorId, safraId, tipoSafraId };
  }
  try {
    const res = await api.get(
      `configuracaoGleba/downloadConfiguracaoAgrupada/${grupoId}`,
      {
        responseType: "blob",
        params: queryparams,
      }
    );
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProdutoAdubacao = async (body) => {
  try {
    const res = await api.post(`produtoAdubacao`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createTipoAdubacao = async (body) => {
  try {
    const res = await api.post(`tipoAdubacao`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProdutoTratamentoSetementes = async (body) => {
  try {
    const res = await api.post(`produtoTratamento`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createClasseTratamentoSementes = async (body) => {
  try {
    const res = await api.post(`classeTratamento`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProdutoFoliar = async (body) => {
  try {
    const res = await api.post(`produtoAdubacaoFoliar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const createProdutoHerbicida = async (body) => {
  try {
    const res = await api.post(`produtoHerbicida`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createTipoHerbicida = async (body) => {
  try {
    const res = await api.post(`tipoHerbicida`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProdutoFungicida = async (body) => {
  try {
    const res = await api.post(`produtoFungicida`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProdutoInseticida = async (body) => {
  try {
    const res = await api.post(`produtoInseticida`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createOutrosProdutos = async (body) => {
  try {
    const res = await api.post(`produtoOutrosProdutos`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
// interface CreateClonarGlebaData {
//   glebaId : string;
// 	safraId : string;
// 	tipoSafraId : string;
// 	glebaOrigemId : string;
// 	safraOrigemId : string;
// 	tipoSafraOrigemId:string;
// }

export const createClonarGleba = async (data) => {
  try {
    const res = await api.post(`configuracaoGleba/copiarConfiguracao`, data);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getProdutor = async () => {
  try {
    const res = await api.get(`produtor`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const getFazenda = async (produtorId) => {
  try {
    const res = await api.get(`fazenda?produtorId=${produtorId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const createSimilarAdubacaoFoliar = async (body) => {
  try {
    const res = await api.post(`produtoAdubacaoFoliarSimilar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createSimilarHerbicida = async (body) => {
  try {
    const res = await api.post(`produtoHerbicidaSimilar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createFazenda = async (body) => {
  try {
    const res = await api.post(`fazenda`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editFazenda = async (body, fazendaId) => {
  try {
    const res = await api.put(`fazenda/${fazendaId}`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const deleteFazenda = async (fazendaId) => {
  try {
    const res = await api.delete(`fazenda/${fazendaId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createGlebaInsertion = async (body) => {
  try {
    const res = await api.post(`gleba`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteGleba = async (glebaId) => {
  try {
    const res = await api.delete(`gleba/${glebaId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editGleba = async (body, glebaId) => {
  try {
    const res = await api.put(`gleba/${glebaId}`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createProdutor = async (body) => {
  try {
    const res = await api.post(`produtor`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createSimilarTratamentoSementes = async (body) => {
  try {
    const res = await api.post(`produtoTratamentoSimilar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createSimilarFungicida = async (body) => {
  try {
    const res = await api.post(`produtoFungicidaSimilar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createSimilarInseticida = async (body) => {
  try {
    const res = await api.post(`produtoInseticidaSimilar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createSimilarOutrosProdutos = async (body) => {
  try {
    const res = await api.post(`produtoOutrosProdutosSimilar`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createTipoSafra = async (body) => {
  try {
    const res = await api.post(`tipoSafra`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createSafra = async (body) => {
  try {
    const res = await api.post(`safra`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const createEpoca = async (body) => {
  try {
    const res = await api.post(`epocaAdubacao`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editTipoAdubacao = async (body, tipoAdubacaoId) => {
  try {
    const res = await api.put(`tipoAdubacao/${tipoAdubacaoId}`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editCulturaSementes = async (body, culturaId) => {
  try {
    const res = await api.put(`cultura/${culturaId}`, body);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteCultura = async (culturaId) => {
  try {
    const res = await api.delete(`cultura/${culturaId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editCultivarSementes = async (nome, cultivarId, cultura) => {
  try {
    const res = await api.put(`cultivar/${cultivarId}`, nome, cultura);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteCultivar = async (cultivarId) => {
  try {
    const res = await api.delete(`cultivar/${cultivarId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editPropositoSementes = async (nome, propositoId) => {
  try {
    const res = await api.put(`proposito/${propositoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProposito = async (propositoId) => {
  try {
    const res = await api.delete(`proposito/${propositoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutoAdubacao = async (produtoId) => {
  try {
    const res = await api.delete(`produtoAdubacao/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoAdubacao = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoAdubacao/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteTipoAdubacao = async (tipoId) => {
  try {
    const res = await api.delete(`tipoAdubacao/${tipoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editTipoAdubacaoCadastro = async (nome, tipoId) => {
  try {
    const res = await api.put(`tipoAdubacao/${tipoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteEpocaAdubacao = async (epocaId) => {
  try {
    const res = await api.delete(`epocaAdubacao/${epocaId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editEpocaAdubacao = async (nome, epocaId) => {
  try {
    const res = await api.put(`epocaAdubacao/${epocaId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutoAdubacaoFoliar = async (produtoId) => {
  try {
    const res = await api.delete(`produtoAdubacaoFoliar/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoAdubacaoFoliar = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoAdubacaoFoliar/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const deleteProdutoTratamentoSementes = async (produtoId) => {
  try {
    const res = await api.delete(`produtoTratamento/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoTratamentoSementes = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoTratamento/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteClasseTratamentoSementes = async (classeId) => {
  try {
    const res = await api.delete(`classeTratamento/${classeId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editClasseTratamentoSementes = async (nome, classeId) => {
  try {
    const res = await api.put(`classeTratamento/${classeId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutoHerbicida = async (produtoId) => {
  try {
    const res = await api.delete(`produtoHerbicida/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoHerbicida = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoHerbicida/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteTipoHerbicida = async (tipoId) => {
  try {
    const res = await api.delete(`tipoHerbicida/${tipoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editTipoHerbicida = async (nome, tipoId) => {
  try {
    const res = await api.put(`tipoHerbicida/${tipoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutoFungicida = async (produtoId) => {
  try {
    const res = await api.delete(`produtoFungicida/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoFungicida = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoFungicida/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutoInseticica = async (produtoId) => {
  try {
    const res = await api.delete(`produtoInseticida/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoInseticida = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoInseticida/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutoOutrosProdutos = async (produtoId) => {
  try {
    const res = await api.delete(`produtoOutrosProdutos/${produtoId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutoOutrosProdutos = async (nome, produtoId) => {
  try {
    const res = await api.put(`produtoOutrosProdutos/${produtoId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteProdutor = async (produtorId) => {
  try {
    const res = await api.delete(`produtor/${produtorId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editProdutor = async (nome, produtorId) => {
  try {
    const res = await api.put(`produtor/${produtorId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteTipoSafra = async (tipoSafraId) => {
  try {
    const res = await api.delete(`tipoSafra/${tipoSafraId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editTipoSafra = async (nome, tipoSafraId) => {
  try {
    const res = await api.put(`tipoSafra/${tipoSafraId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteSafra = async (safraId) => {
  try {
    const res = await api.delete(`safra/${safraId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const editSafra = async (nome, safraId) => {
  try {
    const res = await api.put(`safra/${safraId}`, nome);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
export const deleteConfigurcao = async (configId) => {
  try {
    const res = await api.delete(`configuracaoGleba/${configId}`);
    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};

export const getDowloadConfiguracaoExcel = async (tipoSafraId, safraId) => {
  try {
    const res = await api.get(
      `/configuracaoGleba/downloadConfiguracaoPorGleba/excel/${safraId}/${tipoSafraId}/relatorio`,
      {
        responseType: "blob",
      }
    );

    return {
      res,
      error: null,
    };
  } catch (error) {
    return {
      res: null,
      error,
    };
  }
};
