import React, { useState, useEffect } from "react";
import { Button, SelectPicker } from "rsuite";
import { useQuery } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import {
  getGlebas,
  getSafras,
  getTipoSafra,
  getProdutores,
  getFazenda,
  createClonarGleba,
  getProdutor,
} from "../../../Services/programation.service";
const PanelClonarGleba = () => {
  const [selectOptionProdutorOrigem, setSelectOptionProdutorOrigem] =
    useState("");
  const [selectOptionFazendaOrigem, setSelectOptionFazendaOrigem] =
    useState("");
  const [selectOptionGlebaOrigem, setSelectOptionGlebaOrigem] = useState("");
  const [selectOptionSafraOrigem, setSelectOptionSafraOrigem] = useState("");
  const [selectOptionTipoSafraOrigem, setSelectOptionTipoSafraOrigem] =
    useState("");

  const [selectOptionProdutorDestino, setSelectOptionProdutorDestino] =
    useState("");
  const [selectOptionFazendaDestino, setSelectOptionFazendaDestino] =
    useState("");
  const [selectOptionGlebaDestino, setSelectOptionGlebaDestino] = useState("");
  const [selectOptionSafraDestino, setSelectOptionSafraDestino] = useState("");
  const [selectOptionTipoSafraDestino, setSelectOptionTipoSafraDestino] =
    useState("");
  const [showSelectsOrigem, setShowSelectsOrigem] = useState(false);
  const [showSelectsDestino, setShowSelectsDestino] = useState(false);

  const getTipoSafrafetch = async () => {
    const { res } = await getTipoSafra();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((tipoSafra) => !tipoSafra.excluido)
        .map((tipoSafra) => ({
          label: tipoSafra.nome,
          value: tipoSafra.id,
        }));
    }
    return [];
  };

  const getProdutoresfetch = async () => {
    const { res } = await getProdutores();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produtor) => !produtor.excluido)
        .map((produtor) => ({
          label: produtor.nome,
          value: produtor.id,
        }));
    }
    return [];
  };

  const getSafrasfetch = async () => {
    const { res } = await getSafras();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((safra) => !safra.excluido)
        .map((safra) => ({
          label: safra.nome,
          value: safra.id,
        }));
    }
    return [];
  };

  const getFazendasfetch = async () => {
    if (selectOptionProdutorOrigem.produtorOrigemId) {
      const { res } = await getFazenda(
        selectOptionProdutorOrigem.produtorOrigemId
      );

      if (res?.data) {
        const { data } = res;
        return data
          .filter((fazenda) => !fazenda.excluido)
          .map((fazenda) => ({
            label: fazenda.nome,
            value: fazenda.id,
          }));
      }
    }
    return [];
  };
  const getFazendasfetchDetisno = async () => {
    if (selectOptionProdutorDestino.produtorId) {
      const { res } = await getFazenda(selectOptionProdutorDestino.produtorId);

      if (res?.data) {
        const { data } = res;
        return data
          .filter((fazenda) => !fazenda.excluido)
          .map((fazenda) => ({
            label: fazenda.nome,
            value: fazenda.id,
          }));
      }
    }
    return [];
  };

  const getGlebasfetch = async () => {
    if (selectOptionFazendaOrigem?.fazendaOrigemId) {
      const { res } = await getGlebas(
        selectOptionFazendaOrigem?.fazendaOrigemId
      );
      if (res?.data) {
        const { data } = res;
        return data
          .filter((gleba) => !gleba.excluido)
          .map((gleba) => ({
            label: gleba.nome,
            value: gleba.id,
            area: gleba.area,
          }));
      }
    }
    return [];
  };
  const getGlebasfetchDestino = async () => {
    if (selectOptionFazendaDestino?.fazendaId) {
      const { res } = await getGlebas(selectOptionFazendaDestino?.fazendaId);
      if (res?.data) {
        const { data } = res;
        return data
          .filter((gleba) => !gleba.excluido)
          .map((gleba) => ({
            label: gleba.nome,
            value: gleba.id,
            area: gleba.area,
          }));
      }
    }
    return [];
  };

  const getProdutorFetch = async () => {
    const { res } = await getProdutor();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produtor) => !produtor.excluido)
        .map((produtor) => ({
          label: produtor.nome,
          value: produtor.id,
        }));
    }
    return [];
  };

  const handleSelectProdutorOrigemChange = (value) => {
    setSelectOptionProdutorOrigem((prevState) => ({
      ...prevState,
      produtorOrigemId: value,
    }));
  };
  const handleSelectFazendaOrigemChange = (value) => {
    setSelectOptionFazendaOrigem((prevState) => ({
      ...prevState,
      fazendaOrigemId: value,
    }));
  };
  const handleSelectGlebaOrigemChange = (value) => {
    setSelectOptionGlebaOrigem((prevState) => ({
      ...prevState,
      glebaOrigemId: value,
    }));
  };
  const handleSelectSafraOrigemChange = (value) => {
    setSelectOptionSafraOrigem((prevState) => ({
      ...prevState,
      safraOrigemId: value,
    }));
  };
  const handleSelectTipoSafraOrigemChange = (value) => {
    setSelectOptionTipoSafraOrigem((prevState) => ({
      ...prevState,
      tipoSafraOrigemId: value,
    }));
  };

  const handleSelectProdutorDestinoChange = (value) => {
    setSelectOptionProdutorDestino((prevState) => ({
      ...prevState,
      produtorId: value,
    }));
  };
  const handleSelectFazendaDestinoChange = (value) => {
    setSelectOptionFazendaDestino((prevState) => ({
      ...prevState,
      fazendaId: value,
    }));
  };
  const handleSelectGlebaDestinoChange = (value) => {
    setSelectOptionGlebaDestino((prevState) => ({
      ...prevState,
      glebaId: value,
    }));
  };
  const handleSelectSafraDestinoChange = (value) => {
    setSelectOptionSafraDestino((prevState) => ({
      ...prevState,
      safraId: value,
    }));
  };
  const handleSelectTipoSafraDestinoChange = (value) => {
    setSelectOptionTipoSafraDestino((prevState) => ({
      ...prevState,
      tipoSafraId: value,
    }));
  };
  const handleSubmit = async (body) => {
    const { res } = await createClonarGleba({
      safraId: selectOptionSafraDestino.safraId,
      tipoSafraId: selectOptionTipoSafraDestino.tipoSafraId,
      glebaId: selectOptionGlebaDestino.glebaId,
      safraOrigemId: selectOptionSafraOrigem.safraOrigemId,
      tipoSafraOrigemId: selectOptionTipoSafraOrigem.tipoSafraOrigemId,
      glebaOrigemId: selectOptionGlebaOrigem.glebaOrigemId,
    });
    if (res) {
      toast.success("Gleba clonada com sucesso! ");
    } else {
      toast.error("Erro ao clonar Gleba! ");
    }
  };
  const tiposSafraQuery = useQuery(["tiposSafraQuery"], getTipoSafrafetch, []);
  const safrasQuery = useQuery(["safrasQuery"], getSafrasfetch, []);
  const produtorQuery = useQuery(["produtorQuery"], getProdutorFetch, []);
  const fazendaQuery = useQuery(
    ["fazendaQuery", selectOptionProdutorDestino?.produtorId],
    getFazendasfetchDetisno,
    []
  );
  const glebasQuery = useQuery(
    ["glebasQuery", selectOptionFazendaDestino?.fazendaId],
    getGlebasfetchDestino,
    []
  );

  const glebasQueryOrigem = useQuery(
    ["glebasQueryOrigem", selectOptionFazendaOrigem?.fazendaOrigemId],
    getGlebasfetch,
    []
  );

  const produtoresQueryOrigem = useQuery(
    ["produtoresQueryOrigem"],
    getProdutoresfetch,
    []
  );
  const fazendaQueryOrigem = useQuery(
    ["fazendaQueryOrigem", selectOptionProdutorOrigem?.produtorOrigemId],
    getFazendasfetch,
    []
  );
  useEffect(() => {
    if (selectOptionGlebaOrigem.glebaOrigemId) {
      setShowSelectsOrigem(true);
    } else {
      setShowSelectsOrigem(false);
    }
    if (selectOptionGlebaDestino.glebaId) {
      setShowSelectsDestino(true);
    } else {
      setShowSelectsDestino(false);
    }
  }, [selectOptionGlebaOrigem, selectOptionGlebaDestino]);

  return (
    <div className="panel-container-insumos">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="header-panel-insumos"> Clonar Gleba </div>
      <div className="generating-container-reports">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "15px 0 0 15px",
          }}
        >
          <h6
            style={{
              fontWeight: "bold",
              color: "rgba(1, 104, 32, 0.81)",
              marginBottom: "10px",
            }}
          >
            Selecione a gleba Origem
          </h6>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              Selecione o produtor
              <SelectPicker
                onChange={handleSelectProdutorOrigemChange}
                data={produtoresQueryOrigem.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                  height: "100%",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              Selecione a fazenda
              <SelectPicker
                onChange={handleSelectFazendaOrigemChange}
                data={fazendaQueryOrigem.data}
                disabled={!selectOptionProdutorOrigem}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                  height: "100%",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              Selecione a gleba
              <SelectPicker
                onChange={handleSelectGlebaOrigemChange}
                disabled={!selectOptionFazendaOrigem}
                data={glebasQueryOrigem.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                  height: "100%",
                }}
              />
            </div>
          </div>
          {!showSelectsOrigem ? (
            ""
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Selecione a safra
                <SelectPicker
                  onChange={handleSelectSafraOrigemChange}
                  data={safrasQuery.data}
                  style={{
                    width: 200,
                    border: "solid 1px rgba(1, 104, 32, 0.81)",
                    borderRadius: "5px",
                    marginRight: "15px",
                    height: "100%",
                  }}
                />
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                Selecione o tipo de safra
                <SelectPicker
                  onChange={handleSelectTipoSafraOrigemChange}
                  data={tiposSafraQuery.data}
                  style={{
                    width: 200,
                    border: "solid 1px rgba(1, 104, 32, 0.81)",
                    borderRadius: "5px",
                    marginRight: "15px",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "15px 0 0 15px",
          }}
        >
          <h6
            style={{
              fontWeight: "bold",
              color: "rgba(1, 104, 32, 0.81)",
              marginBottom: "10px",
            }}
          >
            Selecione a gleba Destino
          </h6>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              Selecione o produtor
              <SelectPicker
                disabled={!selectOptionProdutorOrigem}
                onChange={handleSelectProdutorDestinoChange}
                data={produtorQuery.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                  height: "100%",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              Selecione a fazenda
              <SelectPicker
                disabled={!selectOptionProdutorDestino}
                onChange={handleSelectFazendaDestinoChange}
                data={fazendaQuery.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                  height: "100%",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              Selecione a gleba
              <SelectPicker
                onChange={handleSelectGlebaDestinoChange}
                disabled={!selectOptionFazendaDestino}
                data={glebasQuery.data}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                  height: "100%",
                }}
              />
            </div>
          </div>
          {!showSelectsDestino ? (
            ""
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Selecione a safra
                <SelectPicker
                  onChange={handleSelectSafraDestinoChange}
                  data={safrasQuery.data}
                  style={{
                    width: 200,
                    border: "solid 1px rgba(1, 104, 32, 0.81)",
                    borderRadius: "5px",
                    marginRight: "15px",
                    height: "100%",
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Selecione o tipo de safra
                <SelectPicker
                  onChange={handleSelectTipoSafraDestinoChange}
                  data={tiposSafraQuery.data}
                  style={{
                    width: 200,
                    border: "solid 1px rgba(1, 104, 32, 0.81)",
                    borderRadius: "5px",
                    marginRight: "15px",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          )}

          <Button
            className="button-generate-reports"
            variant="danger"
            size="lg"
            onClick={handleSubmit}
            disabled={false}
          >
            Clonar Gleba
          </Button>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default PanelClonarGleba;
