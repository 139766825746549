import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "rsuite";
import api from "../../Services/api";
import logoSolida from "../../imagens/logoSolida.png";
import "./Login.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [formValue, setFormValue] = useState({ email: "", password: "" });
  const handleFormChange = (value) => {
    setFormValue(value);
  };
  const navigate = useNavigate();

  const handleSubmit = async () => {
    await api
      .post("/auth", {
        email: formValue.email,
        password: formValue.password,
      })
      .then(
        (response) => {
          localStorage.setItem("token", response.data.token);
          navigate("/Home");
        },
        (error) => {
          toast.error("Usuário ou senha incorretos");
        }
      );
  };

  return (
    <>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="login-container">
        <Form
          className="form-login"
          onChange={handleFormChange}
          formValue={formValue}
        >
          <h2>
            <img src={logoSolida} />
          </h2>
          <Form.Group>
            <Form.ControlLabel>Usuário</Form.ControlLabel>
            <Form.Control name="email" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Senha</Form.ControlLabel>
            <Form.Control
              type="password"
              name="password"
            />
          </Form.Group>

          <button onClick={handleSubmit}>ENTRAR</button>
        </Form>
      </div>
    </>
  );
};

export default Login;
