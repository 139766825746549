import React, { useContext } from "react";
import { Input } from "rsuite";
import "./index.css";
import { ConfiguracaoGlebaContext } from "../../../../../../contexts/ConfiguracaoGlebaContext";

function Observacoes() {
  const { configuracaoGleba, setConfiguracaoGleba } = useContext(
    ConfiguracaoGlebaContext
  );
  const handleChange = (observacoes) => {
    setConfiguracaoGleba((prevState) => ({
      ...prevState,
      observacoes,
    }));
  };
  return (
    <div className="table-responsive">
      <div className="title-card">OBSERVAÇÕES</div>
      <Input
        style={{ width: "60%", margin: "10px" }}
        as="textarea"
        value={configuracaoGleba?.observacoes}
        rows={3}
        placeholder="Observações"
        onChange={handleChange}
      />
    </div>
  );
}

export default Observacoes;
