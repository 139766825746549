import React, { useState, useEffect } from "react";

import "./index.css";

import { RelatorioPorGleba } from "../PanelGerarRelatorios/components/RelatorioPorGleba/index";

import { RelatorioAgrupado } from "./components/RelatorioAgrupado";
import { ExportarExcel } from "./components/ExportarExcel";
const PanelGerarRelatorios = () => {
  return (
    <>
      <div
        style={{ width: "65%" }}
        className="panel-container-insumos"
      >
        <div className="header-panel-insumos"> Gerar Relatórios </div>
        <RelatorioPorGleba />
        <RelatorioAgrupado />{" "}
      </div>

      <div
        style={{ width: "65%" }}
        className="panel-container-insumos"
      >
        <div
          className="header-panel-insumos"
          style={{ marginTop: "20px" }}
        >
          Gerar Relatório (Excel)
        </div>
        <ExportarExcel />
      </div>
    </>
  );
};

export default PanelGerarRelatorios;
