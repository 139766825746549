import {
  Modal,
  Button,
  Checkbox,
  Input,
  Header,
  SelectPicker,
  Tag,
  TagGroup,
} from "rsuite";
import React, { useState, useEffect } from "react";
import { RiEdit2Fill } from "react-icons/ri";

import {
  createCultura,
  createProposito,
  createCultivar,
  editCulturaSementes,
  getCultura,
  deleteCultura,
  getSementesCultivar,
  editCultivarSementes,
  deleteCultivar,
  getSementesProposito,
  editPropositoSementes,
  deleteProposito,
} from "../../../../../Services/programation.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import "./index.css";
const ModalSementes = (props) => {
  const [cultivar, setCultivar] = useState("");
  const [cultura, setCultura] = useState("");
  const [proposito, setProposito] = useState("");
  const [sementePorSaca, setSementePorSaca] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPMS, setIsCheckedPMS] = useState(false);
  const [isCheckedEdit, setIsCheckedEdit] = useState(false);
  const [isCheckedEditPMS, setIsCheckedEditPMS] = useState(false);

  const handleSubmit = async (e) => {
    if (
      cultivar.length <= 0 &&
      cultura.length <= 0 &&
      proposito.length <= 0 &&
      !isChecked
    ) {
      toast.warning(
        "Nenhum item foi adicionado ao formulario. Adicione para continuar!"
      );
    } else {
      if (cultivar.length >= 1) {
        const culturaId = selectOptionCultura.culturaId;
        const { res } = await createCultivar({
          nome: cultivar,
          culturaId: culturaId,
        });
        if (res) {
          handleButtonCloseModal();
          toast.success("Cultivar adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o cultivar!");
        }
      }

      if (proposito.length >= 1) {
        const { res } = await createProposito({ nome: proposito });
        if (res) {
          handleButtonCloseModal();
          toast.success("Propósito adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o propósito!");
        }
      }
      if (cultura.length >= 1) {
        const body = {
          nome: cultura,
          isSementePorSaca: isChecked,
          isPms: isCheckedPMS,
        };
        const { res } = await createCultura(body);
        if (res) {
          handleButtonCloseModal();
          toast.success(
            "Cultura  e sementes por saca adicionadas com sucesso!"
          );
        } else {
          toast.error("Erro ao adicionar o cultura e sementes por saca!");
        }
      }
    }
  };
  const handleDeleteCultura = async () => {
    const culturaId = selectedEditCultura?.value;
    const { res } = await deleteCultura(culturaId);
    if (res?.data) {
      toast.success("Cultura excluida com sucesso!");
      handleButtonCloseModal();
      closeModalEditCultura();
      setSelectOption(null);
    } else {
      toast.error("Erro ao excluir cultura!");
      handleButtonCloseModal();
      closeModalEditCultura();
      setSelectOption(null);
    }
  };

  const handleCheckboxChange = () => {
    if (isChecked === false) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };
  const handleCheckboxChangePMS = () => {
    if (isCheckedPMS === false) {
      setIsCheckedPMS(true);
    } else {
      setIsCheckedPMS(false);
    }
  };

  const handleButtonCloseModal = () => {
    setCultura("");
    setCultivar("");
    setIsChecked(false);
    setProposito("");
    setSelectOptionCulturaEdit("");
    setSementePorSaca(0);
    setSelectOption(null);
    setIsCheckedPMS(false);
    setIsChecked(false);
    props.closeModalSementes();
  };
  const [selectOption, setSelectOption] = useState(null);
  const [selectOptionCultura, setSelectOptionCultura] = useState({
    culturaId: "",
  });
  const [selectOptionCulturaEdit, setSelectOptionCulturaEdit] = useState({
    culturaId: "",
  });
  const [selectOptionCulturaEditModal, setSelectOptionCulturaEditModal] =
    useState({
      culturaId: "",
    });
  const [showModalEditCultura, setShowModalEditCultura] = useState(false);
  const [showModalEditCultivar, setShowModalEditCultivar] = useState(false);
  const [showModalEditProposito, setShowModalEditProposito] = useState(false);

  const [selectedEditCultura, setSelectedEditCultura] = useState({});
  const [selectedEditCultivar, setSelectedEditCultivar] = useState({});
  const [selectedEditProposito, setSelectedEditProposito] = useState({});

  const [sementesCulturaEdit, setSementesCulturaEdit] = useState("");
  const [sementesCultivarEdit, setSementesCultivarEdit] = useState("");
  const [sementesPropositoEdit, setSementesPropositoEdit] = useState("");

  const data = [
    { value: 1, label: "Cultura" },
    { value: 2, label: "Cultivar" },
    { value: 3, label: "Propósito" },
  ];
  const openModalEditCultura = (cultura) => {
    setSelectedEditCultura(cultura);
    setShowModalEditCultura(true);
  };
  const openModalEditCultivar = (cultivar) => {
    setSelectedEditCultivar(cultivar);
    setShowModalEditCultivar(true);
  };
  const openModalEditProposito = (proposito) => {
    setSelectedEditProposito(proposito);
    setShowModalEditProposito(true);
  };
  const closeModalEditCultura = () => {
    setShowModalEditCultura(false);
    atualizaCheckedEditCultura();
  };
  const closeModalEditProposito = () => {
    setShowModalEditProposito(false);
  };
  const closeModalEditCultivar = () => {
    setShowModalEditCultivar(false);
  };
  const handleSelectChange = (value) => {
    setSelectOption(value);
  };
  useEffect(() => {
    atualizaCheckedEditCultura();
  }, [selectOption, selectedEditCultura]);

  const getCulturaSementesFetch = async () => {
    if (selectOption === 1) {
      const { res } = await getCultura();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((cultura) => !cultura.excluido)
          .map((cultura) => ({
            label: cultura.nome,
            value: cultura.id,
            sementePorSaca: cultura.sementePorSaca,
          }));
      }
    }
    return [];
  };
  const getCulturaCultivar = async () => {
    const { res } = await getCultura();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((cultura) => !cultura.excluido)
        .map((cultura) => ({
          label: cultura.nome,
          value: cultura.id,
        }));
    }
    return [];
  };

  const getCultivarSementesFetch = async () => {
    if (selectOption === 2) {
      const { res } = await getSementesCultivar(selectOptionCultura.culturaId);
      if (res?.data) {
        const { data } = res;
        return data
          .filter((cultivar) => !cultivar.excluido)
          .map((cultivar) => ({
            label: cultivar.nome,
            value: cultivar.id,
            sementePorSaca: cultivar.sementePorSaca,
          }));
      }
    }
    return [];
  };
  const getCultivarSementesFetchEdit = async () => {
    if (selectOption === 2) {
      const { res } = await getSementesCultivar(
        selectOptionCulturaEdit.culturaId
      );
      if (res?.data) {
        const { data } = res;
        return data
          .filter((cultivar) => !cultivar.excluido)
          .map((cultivar) => ({
            label: cultivar.nome,
            value: cultivar.id,
            sementePorSaca: cultivar.sementePorSaca,
          }));
      }
    }
    return [];
  };
  const getPropositoSementesFetch = async () => {
    if (selectOption === 3) {
      const { res } = await getSementesProposito();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((proposito) => !proposito.excluido)
          .map((proposito) => ({
            label: proposito.nome,
            value: proposito.id,
            sementePorSaca: proposito.sementePorSaca,
          }));
      }
    }
    return [];
  };
  const handleEditCultura = async () => {
    const culturaId = selectedEditCultura?.value;
    const body = {
      nome: sementesCulturaEdit
        ? sementesCulturaEdit
        : selectedEditCultura.label,
      isSementePorSaca: isCheckedEdit,
      isPms: isCheckedEditPMS,
    };
    const { res } = await editCulturaSementes(body, culturaId);
    if (res?.data) {
      toast.success("Cultura editada com sucesso!");
      closeModalEditCultura();
      handleButtonCloseModal();
      setSelectOption(null);
    } else {
      toast.success("Erro ao editar cultura!");
    }
  };

  const handleEditCultivar = async () => {
    const cultivarId = selectedEditCultivar?.value;

    const { res } = await editCultivarSementes(
      {
        nome: sementesCultivarEdit
          ? sementesCultivarEdit
          : selectedEditCultivar?.label,
        culturaId: selectOptionCulturaEditModal.culturaId,
      },
      cultivarId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditCultivar();
      toast.success("Cultivar editado com sucesso!");
      setSelectOptionCulturaEdit("");
      setSelectOption(null);
    } else {
      toast.error("Erro ao editar cultivar!");
    }
  };
  const handleDeleteCultivar = async () => {
    const cultivarId = selectedEditCultivar?.value;
    const { res } = await deleteCultivar(cultivarId);
    if (res?.data) {
      toast.success("Cultivar excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditCultivar();
      setSelectOption(null);
    } else {
      toast.error("Erro ao excluir cultivar!");
      handleButtonCloseModal();
      closeModalEditCultivar();
      setSelectOption(null);
    }
  };

  const handleEditProposito = async () => {
    const propositoId = selectedEditProposito?.value;
    const { res } = await editPropositoSementes(
      { nome: sementesPropositoEdit },
      propositoId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditProposito();
      toast.success("Propósito editado com sucesso!");
      setSelectOption(null);
    } else {
      toast.error("Erro ao editar propósito!");
    }
  };
  const handleDeleteProposito = async () => {
    const propositoId = selectedEditProposito?.value;
    const { res } = await deleteProposito(propositoId);
    if (res?.data) {
      closeModalEditProposito();
      handleButtonCloseModal();
      toast.success("Propósito excluido com sucesso!");
      setSelectOption(null);
    } else {
      closeModalEditProposito();
      handleButtonCloseModal();
      toast.error("Erro ao exlcuir propósito");
      setSelectOption(null);
    }
  };

  const culturaQuerySementes = useQuery(
    ["getCulturaSementesFetch", selectOption],
    getCulturaSementesFetch,
    []
  );
  const culturaQueryCultivar = useQuery(
    ["getCulturaCultivar", props.showModalSementes],
    getCulturaCultivar,
    []
  );
  const cultivarQuerySementes = useQuery(
    ["getCultivarSementesFetch", selectOption, selectOptionCultura.culturaId],
    getCultivarSementesFetch,
    []
  );
  const cultivarQuerySementesEdit = useQuery(
    [
      "getCultivarSementesFetchEdit",
      selectOption,
      selectOptionCulturaEdit.culturaId,
    ],
    getCultivarSementesFetchEdit,
    []
  );
  const culturaQueryCultivarModal = useQuery(
    ["getCulturaCultivar", selectOptionCulturaEditModal],
    getCulturaCultivar,
    []
  );
  const propositoQuerySementes = useQuery(
    ["getPropositoSementesFetch", selectOption],
    getPropositoSementesFetch,
    []
  );

  function atualizaCheckedEditCultura() {
    if (selectedEditCultura.sementePorSaca >= 1) {
      setIsCheckedEdit(true);
    } else {
      setIsCheckedEdit(false);
    }
  }

  const handleCheckboxChangeEdit = () => {
    if (isCheckedEdit === false) {
      setIsCheckedEdit(true);
    } else {
      setIsCheckedEdit(false);
    }
  };
  const handleCheckboxChangeEditPMS = () => {
    if (isCheckedEditPMS === false) {
      setIsCheckedEditPMS(true);
    } else {
      setIsCheckedEditPMS(false);
    }
  };
  const handleSelectProdutoChange = (value) => {
    setSelectOptionCultura((prevState) => ({
      ...prevState,
      culturaId: value,
    }));
  };
  const handleSelectCulturaEditCultivar = (value) => {
    setSelectOptionCulturaEdit((prevState) => ({
      ...prevState,
      culturaId: value,
    }));
  };
  const handleSelectCulturaEditCultivarModal = (value) => {
    setSelectOptionCulturaEditModal((prevState) => ({
      ...prevState,
      culturaId: value,
    }));
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={props.showModalSementes}
        onClose={props.closeModalSementes}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Sementes</Modal.Header>

        <Modal.Body>
          <div className="input-cadastro-column">
            <Header>
              <h5>Cultura</h5>
            </Header>
            <Input
              value={cultura}
              onChange={(e) => setCultura(e)}
              className="input-cadastro"
              style={{ width: 300 }}
              disabled={cultivar || proposito}
              placeholder="Insira a Cultura"
            />{" "}
            <Checkbox
              onChange={handleCheckboxChange}
              checked={isChecked}
              disabled={isCheckedPMS || cultura.length <= 0}
            >
              {" "}
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Sementes por saca
              </p>
            </Checkbox>
            <Checkbox
              disabled={isChecked || cultura.length <= 0}
              onChange={handleCheckboxChangePMS}
              checked={isCheckedPMS}
            >
              {" "}
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>PMS</p>
            </Checkbox>
          </div>

          <div className="input-cadastro-column">
            <Header>
              <h5>Cultivar</h5>
            </Header>

            <Input
              onChange={(e) => setCultivar(e)}
              value={cultivar}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Cultivar"
              disabled={cultura || proposito}
            />
            <SelectPicker
              placeholder={"Selecione a cultura"}
              onChange={handleSelectProdutoChange}
              data={culturaQueryCultivar?.data}
              style={{
                width: 200,
                border: "solid 1px rgba(1, 104, 32, 0.81)",
                borderRadius: "5px",
                marginLeft: "15px",
              }}
              disabled={cultivar.length <= 0}
            />
          </div>
          <div className="input-cadastro-column">
            <Header>
              <h5>Propósito</h5>
            </Header>

            <Input
              disabled={cultura || cultivar}
              value={proposito}
              onChange={(e) => setProposito(e)}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Propósito"
            />
            {/* </Whisper> */}
          </div>
          <SelectPicker
            onChange={handleSelectChange}
            value={selectOption}
            data={data}
            placeholder="Selecione para editar ou excluir"
            style={{
              width: 250,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          {selectOption === 2 && (
            <SelectPicker
              placeholder={"Selecione a cultura"}
              onChange={handleSelectCulturaEditCultivar}
              data={culturaQueryCultivar?.data}
              style={{
                width: 200,
                border: "solid 1px rgba(1, 104, 32, 0.81)",
                borderRadius: "5px",
                marginLeft: "15px",
              }}
            />
          )}

          <TagGroup style={{ margin: "10px 15px 0 0" }}>
            {culturaQuerySementes?.data?.map((cultura) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditCultura(cultura)}
                color="green"
              >
                {cultura?.label} <RiEdit2Fill />
              </Tag>
            ))}
            {cultivarQuerySementesEdit?.data?.map((cultivar) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditCultivar(cultivar)}
                color="green"
              >
                {cultivar?.label} <RiEdit2Fill />
              </Tag>
            ))}
            {propositoQuerySementes?.data?.map((proposito) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditProposito(proposito)}
                color="green"
              >
                {proposito?.label} <RiEdit2Fill />
              </Tag>
            ))}
          </TagGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={handleButtonCloseModal}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditCultura}
        onClose={closeModalEditCultura}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Cultura</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setSementesCulturaEdit(e)}
            className="input-cadastro"
            defaultValue={selectedEditCultura?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova cultura"
          />
          <Checkbox
            onChange={handleCheckboxChangeEdit}
            checked={isCheckedEdit}
            disabled={isCheckedEditPMS}
          >
            {" "}
            <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
              Sementes por saca
            </p>
          </Checkbox>
          <Checkbox
            onChange={handleCheckboxChangeEditPMS}
            checked={isCheckedEditPMS}
            disabled={isCheckedEdit}
          >
            {" "}
            <p style={{ fontWeight: "bold", marginBottom: "5px" }}>PMS</p>
          </Checkbox>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditCultura}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteCultura}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditCultura}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditCultivar}
        onClose={closeModalEditCultivar}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Cultivar</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setSementesCultivarEdit(e)}
            className="input-cadastro"
            defaultValue={selectedEditCultivar?.label}
            style={{ width: 300 }}
            placeholder="Insira o novo cultivar"
          />

          <SelectPicker
            className="input-cadastro"
            placeholder={"Selecione a cultura"}
            onChange={handleSelectCulturaEditCultivarModal}
            data={culturaQueryCultivarModal?.data}
            style={{
              width: 200,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditCultivar}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteCultivar}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditCultivar}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditProposito}
        onClose={closeModalEditProposito}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Propóstio</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setSementesPropositoEdit(e)}
            className="input-cadastro"
            defaultValue={selectedEditProposito?.label}
            style={{ width: 300 }}
            placeholder="Insira o novo propósito"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditProposito}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteProposito}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditProposito}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSementes;
