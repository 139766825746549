import React, { useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";

import ModalAdubacao from "../Modals/ModalAdubacao";
import ModalSementes from "../Modals/ModalSementes";
import { Button, Input } from "rsuite";
import "./index.css";
import "rsuite/dist/rsuite.min.css";
import ModalTratamentoSementes from "../Modals/ModaTratamentoSementes";
import ModalAdubacaoFoliar from "../Modals/ModalAduboFoliar";
import ModalHerbicida from "../Modals/ModalHerbicidas";
import ModalFungicida from "../Modals/ModalFungicidas";
import ModalInseticida from "../Modals/ModalInseticidas";
import ModalOutrosProdutos from "../Modals/ModalOutrosProdutos";

const PanelCadastro = () => {
  const [openModalAdubacao, setOpenModalAdubacao] = useState(false);
  const handleCloseCloseModalAdubacao = () => setOpenModalAdubacao(false);
  const handleOpenModalAdubacao = () => {
    setOpenModalAdubacao(true);
  };

  const [openModalSementes, setOpenModalSementes] = useState(false);
  const handleCloseCloseModalSementes = () => setOpenModalSementes(false);
  const handleOpenModalSementes = () => {
    setOpenModalSementes(true);
  };
  const [openModalTratamentoSementes, setOpenModalTratamentoSementes] =
    useState(false);
  const handleCloseCloseModalTratamentoSementes = () =>
    setOpenModalTratamentoSementes(false);
  const handleOpenModalTratamentoSementes = () => {
    setOpenModalTratamentoSementes(true);
  };

  const [openModalAdubacaoFoliar, setOpenModalAdubacaoFoliar] = useState(false);
  const handleCloseCloseModalAdubacaoFoliar = () => {
    setOpenModalAdubacaoFoliar(false);
  };
  const handleOpenModalAdubacaoFoliar = () => {
    setOpenModalAdubacaoFoliar(true);
  };

  const [openModalHerbicida, setOpenModalHerbicida] = useState(false);
  const handleCloseModalHerbicida = () => setOpenModalHerbicida(false);
  const handleOpenModalHerbicida = () => {
    setOpenModalHerbicida(true);
  };

  const [openModalFungicida, setOpenModalFungicida] = useState(false);
  const handleCloseModalFungicida = () => setOpenModalFungicida(false);
  const handleOpenModalFungicida = () => {
    setOpenModalFungicida(true);
  };

  const [openModalInseticida, setOpenModalInseticida] = useState(false);
  const handleCloseModalInseticida = () => setOpenModalInseticida(false);
  const handleOpenModalInseticida = () => {
    setOpenModalInseticida(true);
  };

  const [openModalOutrosProdutos, setOpenModalOutrosProdutos] = useState(false);
  const handleCloseModalOutrosProdutos = () =>
    setOpenModalOutrosProdutos(false);
  const handleOpenModalOutrosProdutos = () => {
    setOpenModalOutrosProdutos(true);
  };

  return (
    <div className="panel-container-insumos">
      <div className="header-panel-insumos"> Cadastro de insumos </div>

      <div
        style={{ marginTop: "30px" }}
        className="select-primary"
      >
        <div className="select-component-insumos">
          <Button
            className="button-modal"
            variant="danger"
            size="lg"
            onClick={() => handleOpenModalSementes()}
          >
            Semente
            <IoMdAddCircleOutline />
          </Button>
        </div>
        <div className="select-component-insumos">
          <Button
            className="button-modal"
            variant="danger"
            onClick={() => handleOpenModalAdubacao()}
          >
            Adubação
            <IoMdAddCircleOutline />
          </Button>
        </div>
        <div className="select-component-insumos">
          <Button
            className="button-modal"
            variant="danger"
            onClick={() => handleOpenModalAdubacaoFoliar()}
          >
            Adubo Foliar
            <IoMdAddCircleOutline />
          </Button>
        </div>

        <div className="select-component-insumos">
          <Button
            style={{ width: "239px" }}
            className="button-modal2"
            variant="danger"
            onClick={() => handleOpenModalTratamentoSementes()}
          >
            Tratamento de Sementes
            <IoMdAddCircleOutline />
          </Button>
        </div>
        <div className="select-component-insumos">
          <Button
            className="button-modal"
            variant="danger"
            onClick={() => handleOpenModalHerbicida()}
          >
            Herbicidas
            <IoMdAddCircleOutline />
          </Button>
        </div>
      </div>
      <div className="select-second">
        <div className="select-component-insumos">
          <Button
            className="button-modal"
            variant="danger"
            onClick={() => handleOpenModalFungicida()}
          >
            Fungicidas
            <IoMdAddCircleOutline />
          </Button>
        </div>
        <div className="select-component-insumos">
          <Button
            className="button-modal"
            variant="danger"
            onClick={() => handleOpenModalInseticida()}
          >
            Inseticidas
            <IoMdAddCircleOutline />
          </Button>
        </div>
        <div className="select-component-insumos">
          <Button
            className="button-modal2"
            variant="danger"
            style={{ width: "185px" }}
            onClick={() => handleOpenModalOutrosProdutos()}
          >
            Outros produtos
            <IoMdAddCircleOutline />
          </Button>
        </div>
      </div>
      <div>
        <ModalAdubacao
          showModalAdubacao={openModalAdubacao}
          closeModalAdubacao={handleCloseCloseModalAdubacao}
        />
        <ModalSementes
          showModalSementes={openModalSementes}
          closeModalSementes={handleCloseCloseModalSementes}
        />
        <ModalTratamentoSementes
          showModalTratamentoSementes={openModalTratamentoSementes}
          closeModalTratamentoSementes={handleCloseCloseModalTratamentoSementes}
        />
        <ModalAdubacaoFoliar
          showModalAdubacaoFoliar={openModalAdubacaoFoliar}
          closeModalAdubacaoFoliar={handleCloseCloseModalAdubacaoFoliar}
        />
        <ModalHerbicida
          showModalHerbicida={openModalHerbicida}
          closeModalHerbicida={handleCloseModalHerbicida}
        />
        <ModalFungicida
          showModalFungicida={openModalFungicida}
          closeModalFungicida={handleCloseModalFungicida}
        />
        <ModalInseticida
          showModalInseticida={openModalInseticida}
          closeModalInseticida={handleCloseModalInseticida}
        />
        <ModalOutrosProdutos
          showModalOutrosProdutos={openModalOutrosProdutos}
          closeModalOutrosProdutos={handleCloseModalOutrosProdutos}
        />
      </div>
    </div>
  );
};

export default PanelCadastro;
