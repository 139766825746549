import Header from "../../Components/Header/Header";
import { Button, Modal, Input, SelectPicker, TagGroup, Tag } from "rsuite";
import React, { useState, useEffect } from "react";
import {
  getProdutor,
  createFazenda,
  getSafras,
  getFazenda,
  editFazenda,
  deleteFazenda,
  getGlebas,
  createGlebaInsertion,
  deleteGleba,
  editGleba,
  createProdutor,
  createTipoSafra,
  getTipoSafra,
  createSafra,
  deleteProdutor,
  editProdutor,
  deleteTipoSafra,
  editTipoSafra,
  deleteSafra,
  editSafra,
} from "../../Services/programation.service";
import { RiEdit2Fill } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";

import "./index.css";
import { useQuery } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { getTTFB } from "web-vitals";

function CadastroProdutor() {
  const [showModalProdutor, setShowModalProdutor] = useState(false);
  const [showModalFazenda, setShowModalFazenda] = useState(false);
  const [showModalEditFazenda, setShowModalEditFazenda] = useState(false);
  const [selectOptionProdutor, setSelectOptionProdutor] = useState({
    produtorId: "",
  });

  const [selectedFazendaEdit, setSelectedFazendaEdit] = useState({});
  const [selectedGlebaEdit, setSelectedGlebaEdit] = useState({});

  const [produtorFazenda, setProdutorFazenda] = useState("");
  const [fazendaEdit, setFazendaEdit] = useState("");
  const [glebaEdit, setGlebaEdit] = useState("");
  const [areaEdit, setAreaEdit] = useState(0);
  const [showModalGleba, setShowModalGleba] = useState(false);
  const [showModalSafra, setShowModalSafra] = useState(false);
  const [showModalEditGleba, setShowModalEditGleba] = useState(false);
  const [showModalTipoSafra, setShowModalTipoSafra] = useState(false);
  const [selectOptionFazenda, setSelectOptionFazenda] = useState("");
  const [selectOptionTipoSafra, setSelectOptionTipoSafra] = useState({
    tipoSafraId: "",
  });
  const [inputGlebaNome, setInputGlebaNome] = useState("");
  const [inputGlebaArea, setInputGlebaArea] = useState("");
  const [inputSafraNome, setInputSafraNome] = useState("");
  const [nomeProdutor, setNomeProdutor] = useState("");
  const [tipoSafra, setTipoSafra] = useState("");
  const openModalProdutor = () => {
    setShowModalProdutor(true);
  };
  const openModalTipoSafra = () => {
    setShowModalTipoSafra(true);
  };
  const openModalSafra = () => {
    setShowModalSafra(true);
  };
  const closeModalSafra = () => {
    setShowModalSafra(false);
    setInputSafraNome("");
    setSelectOptionTipoSafra({ tipoSafraId: "" });
  };
  const closeModalProdutor = () => {
    setShowModalProdutor(false);
    setNomeProdutor({ nome: "" });
  };
  const closeModalTipoSafra = () => {
    setShowModalTipoSafra(false);
    setTipoSafra({ tipoSafra: "" });
  };

  const openModalFazenda = () => {
    setShowModalFazenda(true);
  };
  const closeModalFazenda = () => {
    setShowModalFazenda(false);
    setSelectOptionProdutor({ produtorId: "" });
    setProdutorFazenda("");
  };
  const openModalEditFazenda = (fazenda) => {
    setSelectedFazendaEdit(fazenda);
    setShowModalEditFazenda(true);
  };

  const closeModalEditFazenda = () => {
    setShowModalEditFazenda(false);
  };

  const openModalGleba = () => {
    setShowModalGleba(true);
  };
  const closeModalGleba = () => {
    setShowModalGleba(false);
    setSelectOptionProdutor({ produtorId: "" });
    setSelectOptionFazenda({ fazendaId: "" });
  };
  const openModalEditGleba = (gleba) => {
    setSelectedGlebaEdit(gleba);
    setShowModalEditGleba(true);
  };

  const closeModalEditGleba = () => {
    setShowModalEditGleba(false);
  };

  const getProdutorFetch = async () => {
    const { res } = await getProdutor();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produtor) => !produtor.excluido)
        .map((produtor) => ({
          label: produtor.nome,
          value: produtor.id,
        }));
    }
    return [];
  };
  const getSafrasFetchCadastro = async () => {
    const { res } = await getSafras();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((safra) => !safra.excluido)
        .map((safra) => ({
          label: safra.nome,
          value: safra.id,
        }));
    }
    return [];
  };
  const getTipoSafraFetch = async () => {
    const { res } = await getTipoSafra();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((tipoSafra) => !tipoSafra.excluido)
        .map((tipoSafra) => ({
          label: tipoSafra.nome,
          value: tipoSafra.id,
        }));
    }
    return [];
  };
  const getFazendasfetch = async () => {
    if (selectOptionProdutor.produtorId) {
      const { res } = await getFazenda(selectOptionProdutor?.produtorId);

      if (res?.data) {
        const { data } = res;
        return data
          .filter((fazenda) => !fazenda.excluido)
          .map((fazenda) => ({
            label: fazenda.nome,
            value: fazenda.id,
          }));
      }
    }
    return [];
  };

  const getGlebasfetch = async () => {
    if (selectOptionProdutor.produtorId) {
      const { res } = await getGlebas(selectOptionFazenda?.fazendaId);
      if (res?.data) {
        const { data } = res;
        return data
          .filter((gleba) => !gleba.excluido)
          .map((gleba) => ({
            label: gleba.nome,
            value: gleba.id,
            area: gleba.area,
          }));
      }
    }
    return [];
  };

  const glebasQueryCadastrarGlebas = useQuery(
    ["glebasQueryCadastrarGlebas", selectOptionFazenda?.fazendaId],
    getGlebasfetch,
    {
      initialData: [],
    }
  );

  const fazendaQueryCadastrarProdutor = useQuery(
    ["fazendaQueryCadastrarProdutor", selectOptionProdutor?.produtorId],
    getFazendasfetch,
    {
      initialData: [],
    }
  );

  const handleSubmitFazenda = async () => {
    if (!selectOptionProdutor.produtorId || produtorFazenda <= 0) {
      toast.warning(
        "Adicione um produtor e um nome para de gleba para continuar!"
      );
    } else {
      const body = {
        nome: produtorFazenda,
        produtorId: selectOptionProdutor.produtorId,
      };
      const { res } = await createFazenda(body);
      if (res?.data) {
        toast.success("Fazenda adicionada com sucesso!");
        closeModalFazenda();
      } else {
        toast.error("Erro ao adicionar a fazenda!");
      }
    }
  };

  const handleSubmitGleba = async () => {
    if (
      !selectOptionProdutor.produtorId ||
      !selectOptionFazenda.fazendaId ||
      inputGlebaNome <= 0 ||
      inputGlebaArea <= 0
    ) {
      toast.warning(
        "Selecione produtor, fazenda e preencha os campos em tela para continuar!"
      );
    } else {
      const body = {
        nome: inputGlebaNome,
        area: parseFloat(inputGlebaArea),
        fazendaId: selectOptionFazenda.fazendaId,
      };
      const { res } = await createGlebaInsertion(body);
      if (res?.data) {
        toast.success("Gleba adicionada com sucesso!");
        closeModalGleba();
      } else {
        toast.error("Erro ao adicionar a gleba!");
      }
    }
  };
  const handleSubmitProdutor = async () => {
    if (nomeProdutor <= 0) {
      toast.warning("Adicione um produtor para continuar");
    } else {
      const body = {
        nome: nomeProdutor,
      };
      const { res } = await createProdutor(body);
      if (res?.data) {
        toast.success("Produtor adicionado com sucesso!");
        closeModalProdutor();
      } else {
        toast.error("Erro ao adicionar produtor!");
      }
    }
  };
  const handleSubmitTipoSafra = async () => {
    if (tipoSafra <= 0) {
      toast.warning("Adicione um tipo safra para continuar");
    } else {
      const body = {
        nome: tipoSafra,
      };
      const { res } = await createTipoSafra(body);
      if (res?.data) {
        toast.success("Tipo safra adicionado com sucesso!");
        closeModalTipoSafra();
      } else {
        toast.error("Erro ao adicionar tipo safra!");
      }
    }
  };
  const handleSubmitSafra = async () => {
    if (!selectOptionTipoSafra.tipoSafraId || inputSafraNome.length <= 0) {
      toast.warning(
        "Selecione o tipo safra e preencha o campo safra para continuar!"
      );
    } else {
      const body = {
        nome: inputSafraNome,
        tipoSafraId: selectOptionTipoSafra.tipoSafraId,
      };
      const { res } = await createSafra(body);
      if (res?.data) {
        toast.success("Safra adicionada com sucesso!");
        closeModalSafra();
      } else {
        toast.error("Erro ao adicionar a safra!");
      }
    }
  };

  const handleEditFazenda = async () => {
    const fazendaId = selectedFazendaEdit?.value;
    const body = {
      nome: fazendaEdit,
    };
    const { res } = await editFazenda(body, fazendaId);
    if (res?.data) {
      toast.success("Fazenda editada com sucesso!");
      closeModalFazenda();
      closeModalEditFazenda();
    } else {
      toast.success("Fazenda editada com sucesso!");
      closeModalFazenda();
      closeModalEditFazenda();
    }
  };
  const handleEditGleba = async () => {
    const glebaId = selectedGlebaEdit?.value;
    const body = {
      nome: glebaEdit ? glebaEdit : selectedGlebaEdit?.label,
      area: parseFloat(areaEdit)
        ? parseFloat(areaEdit)
        : selectedGlebaEdit?.area,
    };
    const { res } = await editGleba(body, glebaId);
    if (res?.data) {
      toast.success("Gleba editada com sucesso!");
      closeModalGleba();
      closeModalEditGleba();
    } else {
      toast.error("Erro ao editar gleba!");
      closeModalGleba();
      closeModalEditGleba();
    }
  };

  const handleDeleteFazenda = async () => {
    const fazendaId = selectedFazendaEdit?.value;
    const { res } = await deleteFazenda(fazendaId);
    if (res?.data) {
      toast.success("Fazenda exlcuida com sucesso!");
      closeModalFazenda();
      closeModalEditFazenda();
    } else {
      toast.success("Erro ao excluir fazenda!");
      closeModalFazenda();
      closeModalEditFazenda();
    }
  };
  const handleDeleteGleba = async () => {
    const glebaId = selectedGlebaEdit?.value;
    const { res } = await deleteGleba(glebaId);
    if (res?.data) {
      toast.success("Gleba excluida com sucesso!");
      closeModalGleba();
      closeModalEditGleba();
    } else {
      toast.error("Erro ao excluir gleba!");
      closeModalGleba();
      closeModalEditGleba();
    }
  };

  const handleSelectProdutorChange = (value) => {
    setSelectOptionProdutor((prevState) => ({
      ...prevState,
      produtorId: value,
    }));
    setSelectOptionFazenda({ fazendaId: "" });
  };
  const handleSelectFazendaChange = (value) => {
    setSelectOptionFazenda((prevState) => ({
      ...prevState,
      fazendaId: value,
    }));
  };
  const handleSelecTipoSafraChange = (value) => {
    setSelectOptionTipoSafra((prevState) => ({
      ...prevState,
      tipoSafraId: value,
    }));
  };
  const [showModalEditProdutor, setShowModalEditProdutor] = useState(false);
  const [produtorEditProdutor, setProdutorEditProdutor] = useState("");
  const [selectedEditProdutor, setSelectedEditProdutor] = useState({});

  const openModalEditProdutor = (produtor) => {
    setSelectedEditProdutor(produtor);
    setShowModalEditProdutor(true);
  };

  const closeModalEditProdutor = () => {
    setShowModalEditProdutor(false);
  };
  const handleEditProdutor = async () => {
    const produtoId = selectedEditProdutor?.value;

    const { res } = await editProdutor(
      { nome: produtorEditProdutor },
      produtoId
    );
    if (res?.data) {
      toast.success("Produtor editado com sucesso!");
      closeModalEditProdutor();
      closeModalProdutor();
    } else {
      toast.error("Erro ao editar produtor!");
      closeModalProdutor();
    }
  };
  const handleDeleteProdutor = async () => {
    const produtoId = selectedEditProdutor?.value;
    const { res } = await deleteProdutor(produtoId);
    if (res?.data) {
      toast.success("Produtor excluido com sucesso!");
      closeModalProdutor();
      closeModalEditProdutor();
    } else {
      toast.error("Erro ao excluir produtor!");
      closeModalProdutor();
      closeModalEditProdutor();
    }
  };
  const [showModalEditTipoSafra, setShowModalEditTipoSafra] = useState(false);
  const [tipoSafraEditTipoSafra, setTipoSafraEditTipoSafra] = useState("");
  const [selectedEditTipoSafra, setSelectedEditTipoSafra] = useState({});

  const openModalEditTipoSafra = (tipoSafra) => {
    setSelectedEditTipoSafra(tipoSafra);
    setShowModalEditTipoSafra(true);
  };

  const closeModalEditTipoSafra = () => {
    setShowModalEditTipoSafra(false);
  };
  const handleEditTipoSafra = async () => {
    const tipoSafraId = selectedEditTipoSafra?.value;

    const { res } = await editTipoSafra(
      { nome: tipoSafraEditTipoSafra },
      tipoSafraId
    );
    if (res?.data) {
      toast.success("Tipo safra editada com sucesso!");
      closeModalEditTipoSafra();
      closeModalTipoSafra();
    } else {
      toast.error("Erro ao editar tipo safra!");
      closeModalEditTipoSafra();
      closeModalTipoSafra();
    }
  };
  const handleDeleteTipoSafra = async () => {
    const tipoSafraId = selectedEditTipoSafra?.value;
    const { res } = await deleteTipoSafra(tipoSafraId);
    if (res?.data) {
      toast.success("Tipo Safra excluida com sucesso!");
      closeModalEditTipoSafra();
      closeModalTipoSafra();
    } else {
      toast.error("Erro ao excluir tipo safra!");
      closeModalEditTipoSafra();
      closeModalTipoSafra();
    }
  };
  const [showModalEditSafra, setShowModalEditSafra] = useState(false);
  const [safraEditSafra, setSafraEditSafra] = useState("");
  const [selectedEditSafra, setSelectedEditSafra] = useState({});

  const openModalEditSafra = (tipoSafra) => {
    setSelectedEditSafra(tipoSafra);
    setShowModalEditSafra(true);
  };

  const closeModalEditSafra = () => {
    setShowModalEditSafra(false);
  };
  const handleEditSafra = async () => {
    const safraId = selectedEditSafra?.value;

    const { res } = await editSafra({ nome: safraEditSafra }, safraId);
    if (res?.data) {
      toast.success("Safra editada com sucesso!");
      closeModalEditSafra();
      closeModalSafra();
    } else {
      toast.error("Erro ao editar  safra!");
      closeModalEditSafra();
      closeModalSafra();
    }
  };
  const handleDeleteSafra = async () => {
    const tipoSafraId = selectedEditSafra?.value;
    const { res } = await deleteSafra(tipoSafraId);
    if (res?.data) {
      toast.success("Safra excluida com sucesso!");
      closeModalEditSafra();
      closeModalSafra();
    } else {
      toast.error("Erro ao excluir  safra!");
      closeModalEditSafra();
      closeModalSafra();
    }
  };
  const produtoresQueryCadastrarProdutor = useQuery(
    ["produtoresQueryCadastrarProdutor", nomeProdutor, showModalEditProdutor],
    getProdutorFetch,
    []
  );
  const tipoSafraQueryCadastrarProdutor = useQuery(
    ["tipoSafraQueryCadastrarProdutor", tipoSafra, showModalEditTipoSafra],
    getTipoSafraFetch,
    {
      initialData: [],
    }
  );
  const safraQueryCadastrarProdutor = useQuery(
    ["getSafrasFetchCadastro", inputSafraNome, showModalEditSafra],
    getSafrasFetchCadastro,
    {
      initialData: [],
    }
  );

  return (
    <div className="container-home">
      <Header />
      <div className="panel-container-insumos">
        <div className="header-panel-insumos">Cadastro Geral</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ width: "239px" }}
            className="button-modal2"
            variant="danger"
            onClick={openModalProdutor}
          >
            Cadastrar Produtor
            <IoMdAddCircleOutline />
          </Button>
          <Button
            style={{ width: "239px" }}
            className="button-modal2"
            variant="danger"
            onClick={openModalFazenda}
          >
            Cadastrar Fazenda
            <IoMdAddCircleOutline />
          </Button>
          <Button
            style={{ width: "239px" }}
            className="button-modal2"
            variant="danger"
            onClick={openModalGleba}
          >
            Cadastrar Gleba
            <IoMdAddCircleOutline />
          </Button>
          <Button
            style={{ width: "239px" }}
            className="button-modal2"
            variant="danger"
            onClick={openModalTipoSafra}
          >
            Cadastrar Tipo Safra
            <IoMdAddCircleOutline />
          </Button>
          <Button
            style={{ width: "239px" }}
            className="button-modal2"
            variant="danger"
            onClick={openModalSafra}
          >
            Cadastrar Safra
            <IoMdAddCircleOutline />
          </Button>
        </div>
      </div>
      <Modal
        open={showModalProdutor}
        onClose={closeModalProdutor}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Cadastrar Produtor</Modal.Header>

        <Modal.Body>
          <Input
            onChange={(e) => setNomeProdutor(e)}
            className="input-cadastro"
            style={{ width: 300 }}
            placeholder="Insira o nome do produtor"
          />{" "}
          <TagGroup style={{ margin: "10px 15px 0 0" }}>
            {produtoresQueryCadastrarProdutor?.data?.map((produtor) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditProdutor(produtor)}
                color="green"
              >
                {produtor?.label} <RiEdit2Fill />
              </Tag>
            ))}
          </TagGroup>
          <Modal
            open={showModalEditProdutor}
            onClose={closeModalEditProdutor}
            size={"xs"}
            className="header-modal"
            backdrop
            backdropStyle={{ zIndex: "1051" }}
            style={{ zIndex: "1051", marginTop: "20px" }}
          >
            <Modal.Header>Editar ou Excluir Produtor</Modal.Header>
            <Modal.Body>
              <Input
                onChange={(e) => setProdutorEditProdutor(e)}
                className="input-cadastro"
                defaultValue={selectedEditProdutor?.label}
                style={{ width: 300 }}
                placeholder="Insira a nova época"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleEditProdutor}
                appearance="primary"
                className="button-editar-produtor"
              >
                EDITAR
              </Button>
              <Button
                onClick={handleDeleteProdutor}
                appearance="primary"
                className="button-excluir-produtor"
              >
                EXCLUIR
              </Button>
              <Button
                onClick={closeModalEditProdutor}
                clear
                appearance="ghost"
                className="button-cancelar-edit-produtor"
              >
                CANCELAR
              </Button>
            </Modal.Footer>
          </Modal>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmitProdutor}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={closeModalProdutor}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalFazenda}
        onClose={closeModalFazenda}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Cadastrar Fazenda</Modal.Header>
        <Modal.Body style={{ display: "flex" }}>
          <SelectPicker
            onChange={handleSelectProdutorChange}
            data={produtoresQueryCadastrarProdutor.data}
            style={{
              width: 200,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          <div>
            <div>
              <Input
                onChange={(e) => setProdutorFazenda(e)}
                value={produtorFazenda}
                className="input-cadastro"
                style={{ width: 300 }}
                placeholder="Insira o nome da fazenda"
              />
              <TagGroup style={{ margin: "10px 15px 0 0" }}>
                {fazendaQueryCadastrarProdutor?.data.map((fazenda) => (
                  <Tag
                    style={{ cursor: "pointer", paddingRight: "5px" }}
                    onClick={() => openModalEditFazenda(fazenda)}
                    color="green"
                  >
                    {fazenda?.label} <RiEdit2Fill />
                  </Tag>
                ))}
              </TagGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmitFazenda}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={closeModalFazenda}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditFazenda}
        onClose={closeModalEditFazenda}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar Fazenda</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setFazendaEdit(e)}
            defaultValue={selectedFazendaEdit?.label}
            className="input-cadastro"
            style={{ width: 300 }}
            placeholder="Insira o nome da fazenda"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditFazenda}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteFazenda}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditFazenda}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ####################################### */}
      <Modal
        open={showModalGleba}
        onClose={closeModalGleba}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Cadastrar Gleba</Modal.Header>
        <Modal.Body style={{ display: "flex" }}>
          <SelectPicker
            onChange={handleSelectProdutorChange}
            placeholder={"Selecione o produtor"}
            data={produtoresQueryCadastrarProdutor.data}
            style={{
              width: 200,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          <SelectPicker
            onChange={handleSelectFazendaChange}
            data={fazendaQueryCadastrarProdutor.data}
            placeholder={"Selecione a Fazenda"}
            style={{
              width: 200,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          <div>
            <div>
              <div style={{ display: "flex" }}>
                <Input
                  onChange={(e) => setInputGlebaNome(e)}
                  className="input-cadastro"
                  style={{ width: 200 }}
                  placeholder="Insira o nome da Gleba"
                />
                <Input
                  onChange={(e) => setInputGlebaArea(e)}
                  className="input-cadastro"
                  style={{ width: 120, marginLeft: "15px" }}
                  placeholder="Insira a área"
                  type="number"
                />
              </div>
              <TagGroup style={{ margin: "10px 15px 0 0" }}>
                {glebasQueryCadastrarGlebas?.data.map((gleba) => (
                  <Tag
                    style={{ cursor: "pointer", paddingRight: "5px" }}
                    onClick={() => openModalEditGleba(gleba)}
                    color="green"
                  >
                    {gleba?.label} <RiEdit2Fill />
                  </Tag>
                ))}
              </TagGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmitGleba}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={closeModalGleba}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditGleba}
        onClose={closeModalEditGleba}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar Gleba</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setGlebaEdit(e)}
            defaultValue={selectedGlebaEdit?.label}
            className="input-cadastro"
            style={{ width: 300 }}
            placeholder="Insira o nome da gleba"
          />
          <Input
            onChange={(e) => setAreaEdit(e)}
            defaultValue={selectedGlebaEdit?.area}
            className="input-cadastro"
            style={{ width: 120, marginTop: "15px" }}
            type="number"
            placeholder="Insira a área"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditGleba}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteGleba}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditGleba}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalTipoSafra}
        onClose={closeModalTipoSafra}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Cadastrar Tipo Safra</Modal.Header>
        <Modal.Body>
          {" "}
          <Input
            onChange={(e) => setTipoSafra(e)}
            className="input-cadastro"
            style={{ width: 300 }}
            placeholder="Insira o tipo Safra"
          />
          <TagGroup style={{ margin: "10px 15px 0 0" }}>
            {tipoSafraQueryCadastrarProdutor?.data?.map((tipoSafra) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditTipoSafra(tipoSafra)}
                color="green"
              >
                {tipoSafra?.label} <RiEdit2Fill />
              </Tag>
            ))}
          </TagGroup>
          <Modal
            open={showModalEditTipoSafra}
            onClose={closeModalEditTipoSafra}
            size={"xs"}
            className="header-modal"
            backdrop
            backdropStyle={{ zIndex: "1051" }}
            style={{ zIndex: "1051", marginTop: "20px" }}
          >
            <Modal.Header>Editar ou Excluir Tipo Safra</Modal.Header>
            <Modal.Body>
              <Input
                onChange={(e) => setTipoSafraEditTipoSafra(e)}
                className="input-cadastro"
                defaultValue={selectedEditTipoSafra?.label}
                style={{ width: 300 }}
                placeholder="Insira a nova época"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleEditTipoSafra}
                appearance="primary"
                className="button-editar-produtor"
              >
                EDITAR
              </Button>
              <Button
                onClick={handleDeleteTipoSafra}
                appearance="primary"
                className="button-excluir-produtor"
              >
                EXCLUIR
              </Button>
              <Button
                onClick={closeModalEditTipoSafra}
                clear
                appearance="ghost"
                className="button-cancelar-edit-produtor"
              >
                CANCELAR
              </Button>
            </Modal.Footer>
          </Modal>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmitTipoSafra}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={closeModalTipoSafra}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalSafra}
        onClose={closeModalSafra}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Cadastrar Safra</Modal.Header>
        <Modal.Body style={{ display: "flex" }}>
          <SelectPicker
            onChange={handleSelecTipoSafraChange}
            placeholder={"Selecione o Tipo Safra"}
            data={tipoSafraQueryCadastrarProdutor.data}
            style={{
              width: 200,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          <div>
            <div>
              <div style={{ display: "flex" }}>
                <Input
                  onChange={(e) => setInputSafraNome(e)}
                  className="input-cadastro"
                  style={{ width: 200 }}
                  placeholder="Insira a Safra"
                />
              </div>
              <TagGroup style={{ margin: "10px 15px 0 0" }}>
                {safraQueryCadastrarProdutor?.data.map((safra) => (
                  <Tag
                    style={{ cursor: "pointer", paddingRight: "5px" }}
                    onClick={() => openModalEditSafra(safra)}
                    color="green"
                  >
                    {safra?.label} <RiEdit2Fill />
                  </Tag>
                ))}
              </TagGroup>
            </div>
          </div>
          <Modal
            open={showModalEditSafra}
            onClose={closeModalEditSafra}
            size={"xs"}
            className="header-modal"
            backdrop
            backdropStyle={{ zIndex: "1051" }}
            style={{ zIndex: "1051", marginTop: "20px" }}
          >
            <Modal.Header>Editar ou Excluir Safra</Modal.Header>
            <Modal.Body>
              <Input
                onChange={(e) => setSafraEditSafra(e)}
                className="input-cadastro"
                defaultValue={selectedEditSafra?.label}
                style={{ width: 300 }}
                placeholder="Insira a nova safra"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleEditSafra}
                appearance="primary"
                className="button-editar-produtor"
              >
                EDITAR
              </Button>
              <Button
                onClick={handleDeleteSafra}
                appearance="primary"
                className="button-excluir-produtor"
              >
                EXCLUIR
              </Button>
              <Button
                onClick={closeModalEditSafra}
                clear
                appearance="ghost"
                className="button-cancelar-edit-produtor"
              >
                CANCELAR
              </Button>
            </Modal.Footer>
          </Modal>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmitSafra}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={closeModalSafra}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default CadastroProdutor;
