import Header from "../../Components/Header/Header";
import PanelCadastro from "./Components/PanelCadastro";

function CadastroInsumos() {
  return (
    <div className="container-home">
      <Header />
      <PanelCadastro />
    </div>
  );
}

export default CadastroInsumos;
