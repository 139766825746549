import {
  Modal,
  Button,
  SelectPicker,
  Input,
  Header,
  Tag,
  TagGroup,
} from "rsuite";
import { RiEdit2Fill } from "react-icons/ri";

import React, { useState } from "react";
import {
  createProdutoAdubacao,
  createTipoAdubacao,
  createEpoca,
  getProdutoAdubacao,
  deleteProdutoAdubacao,
  editProdutoAdubacao,
  getTipoAdubacao,
  deleteTipoAdubacao,
  editTipoAdubacaoCadastro,
  deleteEpocaAdubacao,
  editEpocaAdubacao,
  getEpocaAdubacao,
} from "../../../../../Services/programation.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import "./index.css";
const ModalAdubacao = (props) => {
  const [produto, setProduto] = useState("");
  const [tipo, setTipo] = useState("");
  const [epoca, setEpoca] = useState("");

  const handleSubmit = async (e) => {
    // Primeira requisição POST para a rota 'rotaNome'
    if (produto.length <= 0 && tipo.length <= 0 && epoca.length <= 0) {
      toast.warning(
        "Nenhum item foi adicionado ao formulario. Adicione pelo menos um item para continuar!"
      );
    } else {
      if (produto.length >= 1) {
        const { res } = await createProdutoAdubacao({ nome: produto });
        if (res) {
          handleButtonCloseModal();
          toast.success("Produto adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o Produto!");
        }
      }
      if (tipo.length >= 1) {
        const { res } = await createTipoAdubacao({ nome: tipo });
        if (res) {
          handleButtonCloseModal();
          toast.success("Tipo adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o tipo!");
        }
      }
      if (epoca.length >= 1) {
        const { res } = await createEpoca({ nome: epoca });
        if (res) {
          handleButtonCloseModal();
          toast.success("Época adicionada com sucesso!");
        } else {
          toast.error("Erro ao adicionar o época!");
        }
      }
    }
  };
  const handleButtonCloseModal = () => {
    setProduto("");
    setTipo("");
    setEpoca("");
    setSelectOption(null);
    props.closeModalAdubacao();
  };
  const data = [
    { value: 1, label: "Produto" },
    { value: 2, label: "Tipo" },
    { value: 3, label: "Época" },
  ];
  const [selectOption, setSelectOption] = useState(null);
  const [showModalEditProduto, setShowModalEditProduto] = useState(false);
  const [showModalEditTipo, setShowModalEditTipo] = useState(false);
  const [showModalEditEpoca, setShowModalEditEpoca] = useState(false);

  const [selectedEditProduto, setSelectedEditProduto] = useState({});
  const [selectedEditTipo, setSelectedEditTipo] = useState({});
  const [selectedEditEpoca, setSelectedEditEpoca] = useState({});

  const [produtoEditProduto, setProdutoEditProduto] = useState("");
  const [tipoEditTipo, setTipoEditTipo] = useState("");
  const [epocaEditEpoca, setEpocaEditEpoca] = useState("");

  const openModalEditProduto = (produto) => {
    setSelectedEditProduto(produto);
    setShowModalEditProduto(true);
  };
  const openModalEditTipo = (tipo) => {
    setSelectedEditTipo(tipo);
    setShowModalEditTipo(true);
  };
  const openModalEditEpoca = (epoca) => {
    setSelectedEditEpoca(epoca);
    setShowModalEditEpoca(true);
  };

  const closeModalEditProduto = () => {
    setShowModalEditProduto(false);
  };
  const closeModalEditTipo = () => {
    setShowModalEditTipo(false);
  };
  const closeModalEditEpoca = () => {
    setShowModalEditEpoca(false);
  };

  const handleSelectChange = (value) => {
    setSelectOption(value);
  };
  const getProdutoAdubacaoFetch = async () => {
    if (selectOption === 1) {
      const { res } = await getProdutoAdubacao();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((produto) => !produto.excluido)
          .map((produto) => ({
            label: produto.nome,
            value: produto.id,
          }));
      }
    }
    return [];
  };
  const getTipoAdubacaoFetch = async () => {
    if (selectOption === 2) {
      const { res } = await getTipoAdubacao();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((tipo) => !tipo.excluido)
          .map((tipo) => ({
            label: tipo.nome,
            value: tipo.id,
          }));
      }
    }
    return [];
  };
  const getEpocaAdubacaoFetch = async () => {
    if (selectOption === 3) {
      const { res } = await getEpocaAdubacao();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((epoca) => !epoca.excluido)
          .map((epoca) => ({
            label: epoca.nome,
            value: epoca.id,
          }));
      }
    }
    return [];
  };
  const handleEditProduto = async () => {
    const produtoId = selectedEditProduto?.value;

    const { res } = await editProdutoAdubacao(
      { nome: produtoEditProduto },
      produtoId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditProduto();
      toast.success("Produto editado com sucesso!");
      setSelectOption(null);
    } else {
      toast.error("Erro ao editar produto!");
    }
  };
  const handleDeleteProduto = async () => {
    const cultivarId = selectedEditProduto?.value;
    const { res } = await deleteProdutoAdubacao(cultivarId);
    if (res?.data) {
      toast.success("Produto excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOption(null);
    } else {
      toast.error("Erro ao excluir produto!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOption(null);
    }
  };
  const handleEditTipo = async () => {
    const tipoId = selectedEditTipo?.value;

    const { res } = await editTipoAdubacaoCadastro(
      { nome: tipoEditTipo },
      tipoId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditTipo();
      toast.success("Tipo editado com sucesso!");
      setSelectOption(null);
    } else {
      toast.error("Erro ao editar produto!");
    }
  };
  const handleDeleteTipo = async () => {
    const tipoId = selectedEditTipo?.value;
    const { res } = await deleteTipoAdubacao(tipoId);
    if (res?.data) {
      toast.success("Tipo excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditTipo();
      setSelectOption(null);
    } else {
      toast.error("Erro ao excluir tipo!");
      handleButtonCloseModal();
      closeModalEditTipo();
      setSelectOption(null);
    }
  };
  const handleEditEpoca = async () => {
    const epocaId = selectedEditEpoca?.value;
    const { res } = await editEpocaAdubacao({ nome: epocaEditEpoca }, epocaId);
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditEpoca();
      toast.success("Época editado com sucesso!");
      setSelectOption(null);
    } else {
      toast.error("Erro ao editar época!");
      closeModalEditEpoca();
    }
  };
  const handleDeleteEpoca = async () => {
    const epocaId = selectedEditEpoca?.value;
    const { res } = await deleteEpocaAdubacao(epocaId);
    if (res?.data) {
      toast.success("Época excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditEpoca();
      setSelectOption(null);
    } else {
      toast.error("Erro ao excluir época!");
      handleButtonCloseModal();
      closeModalEditEpoca();
      setSelectOption(null);
    }
  };

  const produtoQueryAdubacao = useQuery(
    ["getProdutoAdubacaoFetch", selectOption],
    getProdutoAdubacaoFetch,
    []
  );
  const tipoQueryAdubacao = useQuery(
    ["getTipoAdubacaoFetch", selectOption],
    getTipoAdubacaoFetch,
    []
  );
  const epocaQueryAdubacao = useQuery(
    ["getEpocaAdubacaoFetch", selectOption],
    getEpocaAdubacaoFetch,
    []
  );
  return (
    <>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={props.showModalAdubacao}
        onClose={handleButtonCloseModal}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Adubação</Modal.Header>

        <Modal.Body>
          <div className="input-cadastro-column">
            <Header>
              <h5>Produto</h5>
            </Header>

            <Input
              onChange={(e) => setProduto(e)}
              value={produto}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Produto"
            />
          </div>
          <div className="input-cadastro-column">
            <Header>
              <h5>Tipo</h5>
            </Header>
            <Input
              value={tipo}
              onChange={(e) => setTipo(e)}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Tipo"
            />
            {/* </Whisper> */}
          </div>
          <div className="input-cadastro-column">
            <Header>
              <h5>Época</h5>
            </Header>
            <Input
              value={epoca}
              onChange={(e) => setEpoca(e)}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira a Época"
            />
            {/* </Whisper> */}
          </div>{" "}
          <SelectPicker
            onChange={handleSelectChange}
            value={selectOption}
            data={data}
            placeholder="Selecione para editar ou excluir"
            style={{
              width: 250,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          <TagGroup style={{ margin: "10px 15px 0 0" }}>
            {produtoQueryAdubacao?.data?.map((produto) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditProduto(produto)}
                color="green"
              >
                {produto?.label} <RiEdit2Fill />
              </Tag>
            ))}
            {tipoQueryAdubacao?.data?.map((tipo) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditTipo(tipo)}
                color="green"
              >
                {tipo?.label} <RiEdit2Fill />
              </Tag>
            ))}
            {epocaQueryAdubacao?.data?.map((epoca) => (
              <Tag
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => openModalEditEpoca(epoca)}
                color="green"
              >
                {epoca?.label} <RiEdit2Fill />
              </Tag>
            ))}
          </TagGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={handleButtonCloseModal}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditProduto}
        onClose={closeModalEditProduto}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Produto</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setProdutoEditProduto(e)}
            className="input-cadastro"
            defaultValue={selectedEditProduto?.label}
            style={{ width: 300 }}
            placeholder="Insira o novo propósito"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditProduto}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteProduto}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditProduto}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditTipo}
        onClose={closeModalEditTipo}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Tipo</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setTipoEditTipo(e)}
            className="input-cadastro"
            defaultValue={selectedEditTipo?.label}
            style={{ width: 300 }}
            placeholder="Insira o novo tipo"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditTipo}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteTipo}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditTipo}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditEpoca}
        onClose={closeModalEditEpoca}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Época</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setEpocaEditEpoca(e)}
            className="input-cadastro"
            defaultValue={selectedEditEpoca?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova época"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditEpoca}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteEpoca}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditEpoca}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAdubacao;
