import {
  Modal,
  Button,
  Input,
  Header,
  CheckPicker,
  SelectPicker,
  TagGroup,
  Tag,
} from "rsuite";
import React, { useState } from "react";
import {
  createProdutoHerbicida,
  createTipoHerbicida,
  getProdutoHerbicida,
  createSimilarHerbicida,
  getTipoHerbicida,
  deleteProdutoHerbicida,
  editProdutoHerbicida,
  deleteTipoHerbicida,
  editTipoHerbicida,
} from "../../../../../Services/programation.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { RiEdit2Fill } from "react-icons/ri";

import "./index.css";
const ModalHerbicida = (props) => {
  const [produto, setProduto] = useState("");
  const [tipo, setTipo] = useState("");

  const handleSubmit = async (e) => {
    // Primeira requisição POST para a rota 'rotaNome'
    if (
      produto.length <= 0 &&
      tipo.length <= 0 &&
      !selectOption.produto &&
      !selectOption.similar.length
    ) {
      toast.warning(
        "Nenhum item foi adicionado ao formulario. Adicione pelo menos um item para continuar!"
      );
    } else {
      if (produto.length >= 1) {
        const { res } = await createProdutoHerbicida({ nome: produto });
        if (res) {
          handleButtonCloseModal();
          toast.success("Produto adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o Produto!");
        }
      }
      if (tipo.length >= 1) {
        const { res } = await createTipoHerbicida({ nome: tipo });
        if (res) {
          handleButtonCloseModal();
          toast.success("Tipo adicionado com sucesso!");
        } else {
          toast.error("Erro ao adicionar o tipo!");
        }
      }
    }
    if (selectOption.produto && selectOption.similar.length <= 0) {
      toast.warning("Selecione ao  menos um (1) similar para continuar!");
    }
    if (selectOption.produto && selectOption.similar.length) {
      const body = {
        produtoHerbicidaId: selectOption.produto,
        produtoHerbicidaSimilaresId: selectOption.similar,
      };
      const { res } = await createSimilarHerbicida(body);
      if (res) {
        toast.success("Similar adicionado com sucesso!");
        handleButtonCloseModal();
      } else {
        toast.error("Erro ao adicionar o Produto!");
      }
    }
  };
  const [selectOption, setSelectOption] = useState({
    produto: "",
    similar: [],
  });

  const handleSelectSimilaresChange = (value) => {
    setSelectOption((prevState) => ({
      ...prevState,
      similar: value,
    }));
  };
  const handleSelectProdutoChange = (value) => {
    setSelectOption((prevState) => ({
      ...prevState,
      produto: value,
    }));
  };

  const getHerbicidaProdutoFetch = async () => {
    const { res } = await getProdutoHerbicida();
    if (res?.data) {
      const { data } = res;
      return data
        .filter((produto) => !produto.excluido)
        .map((produto) => ({
          label: produto.nome,
          value: produto.id,
        }));
    }
    return [];
  };

  const tagList = () => {
    return selectOption.similar.map((similar) => ({
      label: produtoQueryHerbicida.data.find(
        (produto) => produto.value === similar
      ).label,
      value: similar,
    }));
  };

  const removeTag = (tag) => {
    const filterSimilar = selectOption.similar.filter(
      (similar) => similar !== tag.value
    );
    setSelectOption((prevState) => ({
      ...prevState,
      similar: filterSimilar,
    }));
  };

  const handleButtonCloseModal = () => {
    setProduto("");
    setTipo("");
    setSelectOption({
      produto: "",
      similar: [],
    });
    setSelectOptionEdit(null);
    props.closeModalHerbicida();
  };
  const data = [
    { value: 1, label: "Produto" },
    { value: 2, label: "Tipo" },
  ];
  const handleEditProduto = async () => {
    const produtoId = selectedEditProduto?.value;
    const { res } = await editProdutoHerbicida(
      { nome: produtoEditProduto },
      produtoId
    );
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditProduto();
      toast.success("Produto editado com sucesso!");
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao editar produto!");
      closeModalEditProduto();
    }
  };
  const handleDeleteProduto = async () => {
    const epocaId = selectedEditProduto?.value;
    const { res } = await deleteProdutoHerbicida(epocaId);
    if (res?.data) {
      toast.success("Produto excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao excluir produto!");
      handleButtonCloseModal();
      closeModalEditProduto();
      setSelectOptionEdit(null);
    }
  };

  const handleEditTipo = async () => {
    const tipoId = selectedEditTipo?.value;
    const { res } = await editTipoHerbicida({ nome: tipoEditTipo }, tipoId);
    if (res?.data) {
      handleButtonCloseModal();
      closeModalEditTipo();
      toast.success("Tipo editado com sucesso!");
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao editar tipo!");
      closeModalEditTipo();
    }
  };
  const handleDeleteTipo = async () => {
    const tipoId = selectedEditTipo?.value;
    const { res } = await deleteTipoHerbicida(tipoId);
    if (res?.data) {
      toast.success("Tipo excluido com sucesso!");
      handleButtonCloseModal();
      closeModalEditTipo();
      setSelectOptionEdit(null);
    } else {
      toast.error("Erro ao excluir tipo!");
      handleButtonCloseModal();
      closeModalEditTipo();
      setSelectOptionEdit(null);
    }
  };
  const [selectOptionEdit, setSelectOptionEdit] = useState(null);

  const [showModalEditProduto, setShowModalEditProduto] = useState(false);
  const [showModalEditTipo, setShowModalEditTipo] = useState(false);

  const [selectedEditProduto, setSelectedEditProduto] = useState({});
  const [selectedEditTipo, setSelectedEdiTipo] = useState({});

  const [produtoEditProduto, setProdutoEditProduto] = useState("");
  const [tipoEditTipo, setTipoEditTipo] = useState("");

  const getHerbicidasProdutoEditFetch = async () => {
    if (selectOptionEdit === 1) {
      const { res } = await getProdutoHerbicida();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((produto) => !produto.excluido)
          .map((produto) => ({
            label: produto.nome,
            value: produto.id,
          }));
      }
      return [];
    }
  };
  const getHerbicidasTipoFetch = async () => {
    if (selectOptionEdit === 2) {
      const { res } = await getTipoHerbicida();
      if (res?.data) {
        const { data } = res;
        return data
          .filter((tipo) => !tipo.excluido)
          .map((tipo) => ({
            label: tipo.nome,
            value: tipo.id,
          }));
      }
    }
    return [];
  };

  const openModalEditProduto = (produto) => {
    setSelectedEditProduto(produto);
    setShowModalEditProduto(true);
  };
  const closeModalEditProduto = () => {
    setShowModalEditProduto(false);
  };

  const openModalEditTipo = (tipo) => {
    setSelectedEdiTipo(tipo);
    setShowModalEditTipo(true);
  };
  const closeModalEditTipo = () => {
    setShowModalEditTipo(false);
  };
  const handleSelectChangeEdit = (value) => {
    setSelectOptionEdit(value);
  };
  const produtoQueryHerbicidaEdit = useQuery(
    ["getHerbicidaProdutoEditFetch", selectOptionEdit],
    getHerbicidasProdutoEditFetch,
    []
  );
  const tipoQueryHerbicidaEdit = useQuery(
    ["getHerbicidaTipoFetch", selectOptionEdit],
    getHerbicidasTipoFetch,
    []
  );
  const produtoQueryHerbicida = useQuery(
    ["produtoQueryHerbicida", produto, selectOptionEdit],
    getHerbicidaProdutoFetch,
    []
  );
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={props.showModalHerbicida}
        onClose={props.closeModalHerbicida}
        size={"md"}
        className="header-modal"
      >
        <Modal.Header>Herbicida</Modal.Header>

        <Modal.Body>
          <div className="input-cadastro-column">
            <Header>
              <h5>Produto</h5>
            </Header>

            <Input
              onChange={(e) => setProduto(e)}
              value={produto}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Produto"
              disabled={selectOption.produto || selectOption.similar.length}
            />
          </div>
          <div className="input-cadastro-column">
            <Header>
              <h5>Tipo</h5>
            </Header>
            <Input
              disabled={selectOption.produto || selectOption.similar.length}
              value={tipo}
              onChange={(e) => setTipo(e)}
              className="input-cadastro"
              style={{ width: 300 }}
              placeholder="Insira o Tipo"
            />
            {/* </Whisper> */}
          </div>
          <div className="produto-similar-content">
            <div className="select-picker-produto-similar-similar">
              Selecione o produto
              <SelectPicker
                disabled={!!produto}
                onChange={handleSelectProdutoChange}
                data={produtoQueryHerbicida.data}
                placeholder="Selecione o produto"
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                  marginRight: "15px",
                }}
              />{" "}
            </div>
            <div className="select-picker-produto-similar-similar">
              Selecione os similares
              <CheckPicker
                disabled={!!produto || !selectOption.produto}
                data={produtoQueryHerbicida.data}
                searchable={false}
                placeholder="Selecione os similares"
                onChange={handleSelectSimilaresChange}
                value={selectOption.similar}
                style={{
                  width: 200,
                  border: "solid 1px rgba(1, 104, 32, 0.81)",
                  borderRadius: "5px",
                }}
              />
              <TagGroup style={{ margin: "10px 15px 10px 0" }}>
                {tagList().map((tag) => (
                  <Tag
                    closable
                    onClose={() => removeTag(tag)}
                    color="green"
                  >
                    {" "}
                    {tag.label}{" "}
                  </Tag>
                ))}
              </TagGroup>
            </div>
          </div>
          <SelectPicker
            onChange={handleSelectChangeEdit}
            value={selectOptionEdit}
            data={data}
            disabled={selectOption.produto || !!produto || !!tipo}
            placeholder="Selecione para editar ou excluir"
            style={{
              width: 250,
              border: "solid 1px rgba(1, 104, 32, 0.81)",
              borderRadius: "5px",
              marginRight: "15px",
              height: "100%",
            }}
          />
          {selectOption?.produto || !!tipo || !!produto ? (
            ""
          ) : (
            <TagGroup style={{ margin: "10px 15px 0 0" }}>
              {produtoQueryHerbicidaEdit?.data?.map((produto) => (
                <Tag
                  style={{ cursor: "pointer", paddingRight: "5px" }}
                  onClick={() => openModalEditProduto(produto)}
                  color="green"
                >
                  {produto?.label} <RiEdit2Fill />
                </Tag>
              ))}
              {tipoQueryHerbicidaEdit?.data?.map((tipo) => (
                <Tag
                  style={{ cursor: "pointer", paddingRight: "5px" }}
                  onClick={() => openModalEditTipo(tipo)}
                  color="green"
                >
                  {tipo?.label} <RiEdit2Fill />
                </Tag>
              ))}
            </TagGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            appearance="primary"
            className="button-salvar"
          >
            SALVAR
          </Button>
          <Button
            onClick={handleButtonCloseModal}
            clear
            appearance="ghost"
            className="button-cancelar"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditProduto}
        onClose={closeModalEditProduto}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Produto</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setProdutoEditProduto(e)}
            className="input-cadastro"
            defaultValue={selectedEditProduto?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova época"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditProduto}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteProduto}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditProduto}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showModalEditTipo}
        onClose={closeModalEditTipo}
        size={"xs"}
        className="header-modal"
        backdrop
        backdropStyle={{ zIndex: "1051" }}
        style={{ zIndex: "1051", marginTop: "20px" }}
      >
        <Modal.Header>Editar ou Excluir Tipo</Modal.Header>
        <Modal.Body>
          <Input
            onChange={(e) => setTipoEditTipo(e)}
            className="input-cadastro"
            defaultValue={selectedEditTipo?.label}
            style={{ width: 300 }}
            placeholder="Insira a nova época"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditTipo}
            appearance="primary"
            className="button-editar-produtor"
          >
            EDITAR
          </Button>
          <Button
            onClick={handleDeleteTipo}
            appearance="primary"
            className="button-excluir-produtor"
          >
            EXCLUIR
          </Button>
          <Button
            onClick={closeModalEditTipo}
            clear
            appearance="ghost"
            className="button-cancelar-edit-produtor"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalHerbicida;
