import Hamburger from "../../Components/Hamburger/Hamburger";
import logoSolida from "../../imagens/logoSolida.png";

import "./Header.css";
const Header = () => {
  return (
    <div className="header-container">
      <Hamburger style={{ backGroundColor: "red" }} />
      <img
        alt="Solida Sistemas"
        src={logoSolida}
      />
    </div>
  );
};

export default Header;
